import { createSlice, combineReducers, PayloadAction } from "@reduxjs/toolkit";

import {
  createClearableIndexedRequestReducer,
  reduceReducers,
  createIndexedRequestReducerFromThunk,
  createRequestReducerFromThunk,
} from "@skydio/redux_util/src";

import {
  acceptOrganizationInvite,
  inviteUserToOrganization,
  removeUserFromOrganization,
} from "../organizations/asyncThunks";
import { fetchUsers, fetchUser, createUser, updateUser } from "./asyncThunks";

import { APIPagination } from "../pagination/types";
import { UsersPrimaryState, User, APIUser, UsersMap, UserUpdate } from "./types";
import * as pbtypes_tools_cloud_api_enums_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/enums_pb";

const defaultPagination: APIPagination = {
  maxPerPage: 25,
  currentPage: 1,
  totalPages: 1,
};

const initialState: UsersPrimaryState = {
  users: {},
  dispatchTimeout: null,
  requestedIds: [],
  addModalOpen: false,
  pagination: defaultPagination,
};

export const initialUserState: User = {
  uuid: "",
  email: "",
  firstName: "",
  lastName: "",
  displayName: "",
  active: false,
  enabled: false,
  emailStatus: "",
  groups: [],
  organizationId: "",
  organizationName: "",
  organizationPermission: 0,
  organizationGroups: [],
  organizationNotes: "",
  modified: {},
  organizationFlightCount: 0,
  releaseOverridesList: [],
  resourceGroupLinksList: [],
};

export interface FieldUpdatePayload {
  email: string;
  name: keyof UserUpdate;
  value: UserUpdate[keyof UserUpdate] | null;
}

const updateUserState = (state: UsersMap, user: APIUser) => {
  if (!(user.email in state)) {
    state[user.email] = { ...initialUserState };
  }
  Object.assign(state[user.email]!, user, {
    groups: user.groupsList ? user.groupsList.map(group => group.name) : [],
    orgGroups: user.organizationGroupsList
      ? user.organizationGroupsList.map(group => group.name)
      : [],
    organizationFlightCount:
      user.organizationFlightCount || state[user.email]!.organizationFlightCount,
  });
};

const { actions, reducer: primaryReducer } = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsersQueryTimeout(state, { payload }: PayloadAction<number>) {
      state.dispatchTimeout = payload;
    },
    clearUsersQueryTimeout(state) {
      state.dispatchTimeout = null;
    },
    updateUserField(state, { payload }: PayloadAction<FieldUpdatePayload>) {
      const user = state.users[payload.email];
      if (payload.value === null) {
        delete user!.modified[payload.name];
      } else {
        user!.modified = {
          ...user!.modified,
          [payload.name]: payload.value,
        };
      }
    },
    clearUserModifications(state, { payload }: PayloadAction<string>) {
      state.users[payload]!.modified = {};
    },
  },
  extraReducers: builder =>
    builder
      .addCase(fetchUsers.fulfilled, (state, { payload }) => {
        payload.usersList.forEach(user => {
          updateUserState(state.users, user);
        });
        state.requestedIds = payload.usersList.map(({ email }) => email);
        state.pagination = {
          maxPerPage: payload.pagination!.maxPerPage,
          currentPage: payload.pagination!.currentPage,
          totalPages: payload.pagination!.totalPages,
        };
      })
      .addCase(fetchUsers.rejected, state => {
        state.requestedIds = [];
      })
      .addCase(fetchUser.fulfilled, (state, { payload }) => {
        updateUserState(state.users, payload);
      })
      .addCase(createUser.fulfilled, (state, { payload }) => {
        updateUserState(state.users, payload);
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        updateUserState(state.users, payload);
        state.users[payload.email]!.modified = {};
      }),
});

const { clearAction: clearUserRequest, clearableReducer } = createClearableIndexedRequestReducer(
  reduceReducers(
    createIndexedRequestReducerFromThunk(fetchUser),
    createIndexedRequestReducerFromThunk(createUser, "email"),
    createIndexedRequestReducerFromThunk(updateUser, "email")
  ),
  "users"
);

const reducer = combineReducers({
  state: primaryReducer,
  requests: combineReducers({
    users: createRequestReducerFromThunk(fetchUsers),
    user: clearableReducer,
    // NOTE(sam): I'm not 100% happy about putting this here but it's by far the easiest place right now
    invites: createIndexedRequestReducerFromThunk(inviteUserToOrganization, "email"),
    orgRemovals: createIndexedRequestReducerFromThunk(removeUserFromOrganization, "email"),
    acceptedOrgInvite: createRequestReducerFromThunk(acceptOrganizationInvite),
  }),
});
export type UsersState = ReturnType<typeof reducer>;

export const userActions = { ...actions, clearUserRequest };
export default reducer;
