// source: pbtypes/vehicle/skills/actions/global_graph_control/global_graph_control.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_util_global_graph_global_graph_pb = require('../../../../../pbtypes/util/global_graph/global_graph_pb.js');
goog.object.extend(proto, pbtypes_util_global_graph_global_graph_pb);
goog.exportSymbol('proto.skills.GlobalGraphControlActionArgs', null, global);
goog.exportSymbol('proto.skills.GlobalGraphControlActionArgs.ControlCase', null, global);
goog.exportSymbol('proto.skills.GlobalGraphControlActionArgs.Save', null, global);
goog.exportSymbol('proto.skills.GlobalGraphControlActionStateData', null, global);
goog.exportSymbol('proto.skills.GlobalGraphControlResultData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GlobalGraphControlActionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.skills.GlobalGraphControlActionArgs.oneofGroups_);
};
goog.inherits(proto.skills.GlobalGraphControlActionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GlobalGraphControlActionArgs.displayName = 'proto.skills.GlobalGraphControlActionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GlobalGraphControlActionArgs.Save = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GlobalGraphControlActionArgs.Save, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GlobalGraphControlActionArgs.Save.displayName = 'proto.skills.GlobalGraphControlActionArgs.Save';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GlobalGraphControlResultData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GlobalGraphControlResultData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GlobalGraphControlResultData.displayName = 'proto.skills.GlobalGraphControlResultData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GlobalGraphControlActionStateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GlobalGraphControlActionStateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GlobalGraphControlActionStateData.displayName = 'proto.skills.GlobalGraphControlActionStateData';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.skills.GlobalGraphControlActionArgs.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.skills.GlobalGraphControlActionArgs.ControlCase = {
  CONTROL_NOT_SET: 0,
  SAVE: 1
};

/**
 * @return {proto.skills.GlobalGraphControlActionArgs.ControlCase}
 */
proto.skills.GlobalGraphControlActionArgs.prototype.getControlCase = function() {
  return /** @type {proto.skills.GlobalGraphControlActionArgs.ControlCase} */(jspb.Message.computeOneofCase(this, proto.skills.GlobalGraphControlActionArgs.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GlobalGraphControlActionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GlobalGraphControlActionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GlobalGraphControlActionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GlobalGraphControlActionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
save: (f = msg.getSave()) && proto.skills.GlobalGraphControlActionArgs.Save.toObject(includeInstance, f),
timeoutS: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GlobalGraphControlActionArgs}
 */
proto.skills.GlobalGraphControlActionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GlobalGraphControlActionArgs;
  return proto.skills.GlobalGraphControlActionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GlobalGraphControlActionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GlobalGraphControlActionArgs}
 */
proto.skills.GlobalGraphControlActionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.GlobalGraphControlActionArgs.Save;
      reader.readMessage(value,proto.skills.GlobalGraphControlActionArgs.Save.deserializeBinaryFromReader);
      msg.setSave(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTimeoutS(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GlobalGraphControlActionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GlobalGraphControlActionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GlobalGraphControlActionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GlobalGraphControlActionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSave();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.GlobalGraphControlActionArgs.Save.serializeBinaryToWriter
    );
  }
  f = message.getTimeoutS();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GlobalGraphControlActionArgs.Save.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GlobalGraphControlActionArgs.Save.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GlobalGraphControlActionArgs.Save} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GlobalGraphControlActionArgs.Save.toObject = function(includeInstance, msg) {
  var f, obj = {
saveRequest: (f = msg.getSaveRequest()) && pbtypes_util_global_graph_global_graph_pb.SaveRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GlobalGraphControlActionArgs.Save}
 */
proto.skills.GlobalGraphControlActionArgs.Save.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GlobalGraphControlActionArgs.Save;
  return proto.skills.GlobalGraphControlActionArgs.Save.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GlobalGraphControlActionArgs.Save} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GlobalGraphControlActionArgs.Save}
 */
proto.skills.GlobalGraphControlActionArgs.Save.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_util_global_graph_global_graph_pb.SaveRequest;
      reader.readMessage(value,pbtypes_util_global_graph_global_graph_pb.SaveRequest.deserializeBinaryFromReader);
      msg.setSaveRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GlobalGraphControlActionArgs.Save.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GlobalGraphControlActionArgs.Save.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GlobalGraphControlActionArgs.Save} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GlobalGraphControlActionArgs.Save.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSaveRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_util_global_graph_global_graph_pb.SaveRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional global_graph.SaveRequest save_request = 1;
 * @return {?proto.global_graph.SaveRequest}
 */
proto.skills.GlobalGraphControlActionArgs.Save.prototype.getSaveRequest = function() {
  return /** @type{?proto.global_graph.SaveRequest} */ (
    jspb.Message.getWrapperField(this, pbtypes_util_global_graph_global_graph_pb.SaveRequest, 1));
};


/**
 * @param {?proto.global_graph.SaveRequest|undefined} value
 * @return {!proto.skills.GlobalGraphControlActionArgs.Save} returns this
*/
proto.skills.GlobalGraphControlActionArgs.Save.prototype.setSaveRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GlobalGraphControlActionArgs.Save} returns this
 */
proto.skills.GlobalGraphControlActionArgs.Save.prototype.clearSaveRequest = function() {
  return this.setSaveRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GlobalGraphControlActionArgs.Save.prototype.hasSaveRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Save save = 1;
 * @return {?proto.skills.GlobalGraphControlActionArgs.Save}
 */
proto.skills.GlobalGraphControlActionArgs.prototype.getSave = function() {
  return /** @type{?proto.skills.GlobalGraphControlActionArgs.Save} */ (
    jspb.Message.getWrapperField(this, proto.skills.GlobalGraphControlActionArgs.Save, 1));
};


/**
 * @param {?proto.skills.GlobalGraphControlActionArgs.Save|undefined} value
 * @return {!proto.skills.GlobalGraphControlActionArgs} returns this
*/
proto.skills.GlobalGraphControlActionArgs.prototype.setSave = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.skills.GlobalGraphControlActionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GlobalGraphControlActionArgs} returns this
 */
proto.skills.GlobalGraphControlActionArgs.prototype.clearSave = function() {
  return this.setSave(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GlobalGraphControlActionArgs.prototype.hasSave = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float timeout_s = 2;
 * @return {number}
 */
proto.skills.GlobalGraphControlActionArgs.prototype.getTimeoutS = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.GlobalGraphControlActionArgs} returns this
 */
proto.skills.GlobalGraphControlActionArgs.prototype.setTimeoutS = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GlobalGraphControlResultData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GlobalGraphControlResultData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GlobalGraphControlResultData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GlobalGraphControlResultData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GlobalGraphControlResultData}
 */
proto.skills.GlobalGraphControlResultData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GlobalGraphControlResultData;
  return proto.skills.GlobalGraphControlResultData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GlobalGraphControlResultData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GlobalGraphControlResultData}
 */
proto.skills.GlobalGraphControlResultData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GlobalGraphControlResultData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GlobalGraphControlResultData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GlobalGraphControlResultData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GlobalGraphControlResultData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GlobalGraphControlActionStateData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GlobalGraphControlActionStateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GlobalGraphControlActionStateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GlobalGraphControlActionStateData.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GlobalGraphControlActionStateData}
 */
proto.skills.GlobalGraphControlActionStateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GlobalGraphControlActionStateData;
  return proto.skills.GlobalGraphControlActionStateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GlobalGraphControlActionStateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GlobalGraphControlActionStateData}
 */
proto.skills.GlobalGraphControlActionStateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GlobalGraphControlActionStateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GlobalGraphControlActionStateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GlobalGraphControlActionStateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GlobalGraphControlActionStateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.skills);
