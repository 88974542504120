// source: pbtypes/vehicle/skills/waypoints.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_body_trans_pb = require('../../../pbtypes/gen/body/trans_pb.js');
goog.object.extend(proto, pbtypes_gen_body_trans_pb);
var pbtypes_gen_cloud_config_client_type_pb = require('../../../pbtypes/gen/cloud_config/client_type_pb.js');
goog.object.extend(proto, pbtypes_gen_cloud_config_client_type_pb);
var pbtypes_gen_common_fault_pb = require('../../../pbtypes/gen/common/fault_pb.js');
goog.object.extend(proto, pbtypes_gen_common_fault_pb);
var pbtypes_gen_skills_go_to_waypoint_source_pb = require('../../../pbtypes/gen/skills/go_to_waypoint_source_pb.js');
goog.object.extend(proto, pbtypes_gen_skills_go_to_waypoint_source_pb);
var pbtypes_gen_skills_up_and_over_state_pb = require('../../../pbtypes/gen/skills/up_and_over_state_pb.js');
goog.object.extend(proto, pbtypes_gen_skills_up_and_over_state_pb);
var pbtypes_gen_waypoints_landing_pad_anchor_pose_source_pb = require('../../../pbtypes/gen/waypoints/landing_pad_anchor_pose_source_pb.js');
goog.object.extend(proto, pbtypes_gen_waypoints_landing_pad_anchor_pose_source_pb);
var pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb = require('../../../pbtypes/vehicle/nav_cam_reconstruction/pose_id_pb.js');
goog.object.extend(proto, pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb);
var pbtypes_gen_eigen_lcm_Vector3d_pb = require('../../../pbtypes/gen/eigen_lcm/Vector3d_pb.js');
goog.object.extend(proto, pbtypes_gen_eigen_lcm_Vector3d_pb);
var pbtypes_gen_eigen_lcm_Vector2d_pb = require('../../../pbtypes/gen/eigen_lcm/Vector2d_pb.js');
goog.object.extend(proto, pbtypes_gen_eigen_lcm_Vector2d_pb);
goog.exportSymbol('proto.skills.AllowedEdges', null, global);
goog.exportSymbol('proto.skills.AnchorWaypoints', null, global);
goog.exportSymbol('proto.skills.CriticalRTXType', null, global);
goog.exportSymbol('proto.skills.CriticalRTXType.Enum', null, global);
goog.exportSymbol('proto.skills.DisplacementSegments', null, global);
goog.exportSymbol('proto.skills.DistAndTimeToTargetWaypoint', null, global);
goog.exportSymbol('proto.skills.DistanceSegments', null, global);
goog.exportSymbol('proto.skills.DockWaypointEstimate', null, global);
goog.exportSymbol('proto.skills.DockWaypointList', null, global);
goog.exportSymbol('proto.skills.FrameDetails', null, global);
goog.exportSymbol('proto.skills.FrameType', null, global);
goog.exportSymbol('proto.skills.FrameType.Enum', null, global);
goog.exportSymbol('proto.skills.FreeLookMode', null, global);
goog.exportSymbol('proto.skills.FreeLookMode.Enum', null, global);
goog.exportSymbol('proto.skills.GimbalPitch', null, global);
goog.exportSymbol('proto.skills.GimbalPitchMode', null, global);
goog.exportSymbol('proto.skills.GimbalPitchMode.Enum', null, global);
goog.exportSymbol('proto.skills.GoToWaypointRequest', null, global);
goog.exportSymbol('proto.skills.GoToWaypointState', null, global);
goog.exportSymbol('proto.skills.GoToWaypointState.Enum', null, global);
goog.exportSymbol('proto.skills.GoToWaypointStatus', null, global);
goog.exportSymbol('proto.skills.GoToWaypointStatus.TaskStatusCase', null, global);
goog.exportSymbol('proto.skills.GoToWaypointStatusInternal', null, global);
goog.exportSymbol('proto.skills.GoToWaypointStatusInternal.TaskStatusInternalCase', null, global);
goog.exportSymbol('proto.skills.GraphMotionArgs', null, global);
goog.exportSymbol('proto.skills.GraphStatus', null, global);
goog.exportSymbol('proto.skills.GraphStatusInternal', null, global);
goog.exportSymbol('proto.skills.HasIntermediateGoals', null, global);
goog.exportSymbol('proto.skills.Heading', null, global);
goog.exportSymbol('proto.skills.HeadingMode', null, global);
goog.exportSymbol('proto.skills.HeadingMode.Enum', null, global);
goog.exportSymbol('proto.skills.HeadingRate', null, global);
goog.exportSymbol('proto.skills.HeightMode', null, global);
goog.exportSymbol('proto.skills.HeightMode.Enum', null, global);
goog.exportSymbol('proto.skills.IntermediateGoals', null, global);
goog.exportSymbol('proto.skills.Landmark', null, global);
goog.exportSymbol('proto.skills.LandmarkList', null, global);
goog.exportSymbol('proto.skills.LookAtCommand', null, global);
goog.exportSymbol('proto.skills.LookAtMotionArgs', null, global);
goog.exportSymbol('proto.skills.LookAtWaypoint', null, global);
goog.exportSymbol('proto.skills.MotionArgs', null, global);
goog.exportSymbol('proto.skills.Pilot', null, global);
goog.exportSymbol('proto.skills.PositionWaypoint', null, global);
goog.exportSymbol('proto.skills.PositionXY', null, global);
goog.exportSymbol('proto.skills.PositionZ', null, global);
goog.exportSymbol('proto.skills.SegmentEstimate', null, global);
goog.exportSymbol('proto.skills.SegmentEstimates', null, global);
goog.exportSymbol('proto.skills.SkillsRTXSettings', null, global);
goog.exportSymbol('proto.skills.TargetWaypoint', null, global);
goog.exportSymbol('proto.skills.TargetWaypoint.TargetWaypointCase', null, global);
goog.exportSymbol('proto.skills.TargetWaypointData', null, global);
goog.exportSymbol('proto.skills.TraversalMotionArgs', null, global);
goog.exportSymbol('proto.skills.TraversalMotionArgs.HeightModeArgsCase', null, global);
goog.exportSymbol('proto.skills.TraversalStatus', null, global);
goog.exportSymbol('proto.skills.TraversalStatusInternal', null, global);
goog.exportSymbol('proto.skills.UpAndOverProgressUpperBounds', null, global);
goog.exportSymbol('proto.skills.Waypoint', null, global);
goog.exportSymbol('proto.skills.WaypointHeadingsInternal', null, global);
goog.exportSymbol('proto.skills.WaypointKey', null, global);
goog.exportSymbol('proto.skills.WaypointTerminationArgs', null, global);
goog.exportSymbol('proto.skills.WaypointsList', null, global);
goog.exportSymbol('proto.skills.WaypointsRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.FrameType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.FrameType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.FrameType.displayName = 'proto.skills.FrameType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.FrameDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.FrameDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.FrameDetails.displayName = 'proto.skills.FrameDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.PositionXY = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.PositionXY, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.PositionXY.displayName = 'proto.skills.PositionXY';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.PositionZ = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.PositionZ, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.PositionZ.displayName = 'proto.skills.PositionZ';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.Heading = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.Heading, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.Heading.displayName = 'proto.skills.Heading';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GimbalPitch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GimbalPitch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GimbalPitch.displayName = 'proto.skills.GimbalPitch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.HeadingRate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.HeadingRate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.HeadingRate.displayName = 'proto.skills.HeadingRate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.LookAtCommand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.LookAtCommand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.LookAtCommand.displayName = 'proto.skills.LookAtCommand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.Waypoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.Waypoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.Waypoint.displayName = 'proto.skills.Waypoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.PositionWaypoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.PositionWaypoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.PositionWaypoint.displayName = 'proto.skills.PositionWaypoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.LookAtWaypoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.LookAtWaypoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.LookAtWaypoint.displayName = 'proto.skills.LookAtWaypoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.DockWaypointEstimate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.DockWaypointEstimate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.DockWaypointEstimate.displayName = 'proto.skills.DockWaypointEstimate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.DockWaypointList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.DockWaypointList.repeatedFields_, null);
};
goog.inherits(proto.skills.DockWaypointList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.DockWaypointList.displayName = 'proto.skills.DockWaypointList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.DistAndTimeToTargetWaypoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.DistAndTimeToTargetWaypoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.DistAndTimeToTargetWaypoint.displayName = 'proto.skills.DistAndTimeToTargetWaypoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.TargetWaypointData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.TargetWaypointData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.TargetWaypointData.displayName = 'proto.skills.TargetWaypointData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.Pilot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.Pilot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.Pilot.displayName = 'proto.skills.Pilot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.WaypointsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.WaypointsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.WaypointsList.displayName = 'proto.skills.WaypointsList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.WaypointsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.WaypointsRequest.repeatedFields_, null);
};
goog.inherits(proto.skills.WaypointsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.WaypointsRequest.displayName = 'proto.skills.WaypointsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.AnchorWaypoints = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.AnchorWaypoints, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.AnchorWaypoints.displayName = 'proto.skills.AnchorWaypoints';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.Landmark = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.Landmark, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.Landmark.displayName = 'proto.skills.Landmark';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.LandmarkList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.LandmarkList.repeatedFields_, null);
};
goog.inherits(proto.skills.LandmarkList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.LandmarkList.displayName = 'proto.skills.LandmarkList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.HeightMode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.HeightMode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.HeightMode.displayName = 'proto.skills.HeightMode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.TraversalMotionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.skills.TraversalMotionArgs.oneofGroups_);
};
goog.inherits(proto.skills.TraversalMotionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.TraversalMotionArgs.displayName = 'proto.skills.TraversalMotionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.HeadingMode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.HeadingMode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.HeadingMode.displayName = 'proto.skills.HeadingMode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GimbalPitchMode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GimbalPitchMode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GimbalPitchMode.displayName = 'proto.skills.GimbalPitchMode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.LookAtMotionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.LookAtMotionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.LookAtMotionArgs.displayName = 'proto.skills.LookAtMotionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.FreeLookMode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.FreeLookMode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.FreeLookMode.displayName = 'proto.skills.FreeLookMode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.AllowedEdges = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.AllowedEdges, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.AllowedEdges.displayName = 'proto.skills.AllowedEdges';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GraphMotionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GraphMotionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GraphMotionArgs.displayName = 'proto.skills.GraphMotionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.MotionArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.MotionArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.MotionArgs.displayName = 'proto.skills.MotionArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.WaypointTerminationArgs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.WaypointTerminationArgs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.WaypointTerminationArgs.displayName = 'proto.skills.WaypointTerminationArgs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.WaypointKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.WaypointKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.WaypointKey.displayName = 'proto.skills.WaypointKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.TargetWaypoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.skills.TargetWaypoint.oneofGroups_);
};
goog.inherits(proto.skills.TargetWaypoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.TargetWaypoint.displayName = 'proto.skills.TargetWaypoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GoToWaypointRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GoToWaypointRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GoToWaypointRequest.displayName = 'proto.skills.GoToWaypointRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GoToWaypointState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GoToWaypointState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GoToWaypointState.displayName = 'proto.skills.GoToWaypointState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.DistanceSegments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.DistanceSegments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.DistanceSegments.displayName = 'proto.skills.DistanceSegments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.DisplacementSegments = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.DisplacementSegments.repeatedFields_, null);
};
goog.inherits(proto.skills.DisplacementSegments, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.DisplacementSegments.displayName = 'proto.skills.DisplacementSegments';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.SegmentEstimates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.SegmentEstimates.repeatedFields_, null);
};
goog.inherits(proto.skills.SegmentEstimates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.SegmentEstimates.displayName = 'proto.skills.SegmentEstimates';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.SegmentEstimate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.SegmentEstimate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.SegmentEstimate.displayName = 'proto.skills.SegmentEstimate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.HasIntermediateGoals = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.HasIntermediateGoals, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.HasIntermediateGoals.displayName = 'proto.skills.HasIntermediateGoals';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.UpAndOverProgressUpperBounds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.UpAndOverProgressUpperBounds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.UpAndOverProgressUpperBounds.displayName = 'proto.skills.UpAndOverProgressUpperBounds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.TraversalStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.TraversalStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.TraversalStatus.displayName = 'proto.skills.TraversalStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GraphStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GraphStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GraphStatus.displayName = 'proto.skills.GraphStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GoToWaypointStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skills.GoToWaypointStatus.repeatedFields_, proto.skills.GoToWaypointStatus.oneofGroups_);
};
goog.inherits(proto.skills.GoToWaypointStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GoToWaypointStatus.displayName = 'proto.skills.GoToWaypointStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.IntermediateGoals = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.IntermediateGoals, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.IntermediateGoals.displayName = 'proto.skills.IntermediateGoals';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.TraversalStatusInternal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.TraversalStatusInternal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.TraversalStatusInternal.displayName = 'proto.skills.TraversalStatusInternal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GraphStatusInternal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.GraphStatusInternal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GraphStatusInternal.displayName = 'proto.skills.GraphStatusInternal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.WaypointHeadingsInternal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.WaypointHeadingsInternal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.WaypointHeadingsInternal.displayName = 'proto.skills.WaypointHeadingsInternal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.GoToWaypointStatusInternal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.skills.GoToWaypointStatusInternal.oneofGroups_);
};
goog.inherits(proto.skills.GoToWaypointStatusInternal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.GoToWaypointStatusInternal.displayName = 'proto.skills.GoToWaypointStatusInternal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.SkillsRTXSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.SkillsRTXSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.SkillsRTXSettings.displayName = 'proto.skills.SkillsRTXSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skills.CriticalRTXType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skills.CriticalRTXType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skills.CriticalRTXType.displayName = 'proto.skills.CriticalRTXType';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.FrameType.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.FrameType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.FrameType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.FrameType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.FrameType}
 */
proto.skills.FrameType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.FrameType;
  return proto.skills.FrameType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.FrameType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.FrameType}
 */
proto.skills.FrameType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.FrameType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.FrameType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.FrameType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.FrameType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.skills.FrameType.Enum = {
  INVALID: 0,
  NAV: 1,
  WORLD: 2,
  GPS: 3,
  GLOBAL_MAP: 4,
  NAV_TAKEOFF: 5,
  NAV_CAM_POSE_GRAPH: 6,
  WORLD_PILOT_RELATIVE: 7,
  WORLD_TAKEOFF: 8,
  NAV_HEIGHT_ABOVE_GROUND: 9,
  SITE: 10
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.FrameDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.FrameDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.FrameDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.FrameDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
poseId: (f = msg.getPoseId()) && pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb.PoseIdPb.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.FrameDetails}
 */
proto.skills.FrameDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.FrameDetails;
  return proto.skills.FrameDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.FrameDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.FrameDetails}
 */
proto.skills.FrameDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb.PoseIdPb;
      reader.readMessage(value,pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb.PoseIdPb.deserializeBinaryFromReader);
      msg.setPoseId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.FrameDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.FrameDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.FrameDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.FrameDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoseId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb.PoseIdPb.serializeBinaryToWriter
    );
  }
};


/**
 * optional nav_cam_reconstruction.PoseIdPb pose_id = 1;
 * @return {?proto.nav_cam_reconstruction.PoseIdPb}
 */
proto.skills.FrameDetails.prototype.getPoseId = function() {
  return /** @type{?proto.nav_cam_reconstruction.PoseIdPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_vehicle_nav_cam_reconstruction_pose_id_pb.PoseIdPb, 1));
};


/**
 * @param {?proto.nav_cam_reconstruction.PoseIdPb|undefined} value
 * @return {!proto.skills.FrameDetails} returns this
*/
proto.skills.FrameDetails.prototype.setPoseId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.FrameDetails} returns this
 */
proto.skills.FrameDetails.prototype.clearPoseId = function() {
  return this.setPoseId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.FrameDetails.prototype.hasPoseId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.PositionXY.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.PositionXY.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.PositionXY} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PositionXY.toObject = function(includeInstance, msg) {
  var f, obj = {
x: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
y: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
frame: jspb.Message.getFieldWithDefault(msg, 3, 0),
frameDetails: (f = msg.getFrameDetails()) && proto.skills.FrameDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.PositionXY}
 */
proto.skills.PositionXY.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.PositionXY;
  return proto.skills.PositionXY.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.PositionXY} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.PositionXY}
 */
proto.skills.PositionXY.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setY(value);
      break;
    case 3:
      var value = /** @type {!proto.skills.FrameType.Enum} */ (reader.readEnum());
      msg.setFrame(value);
      break;
    case 4:
      var value = new proto.skills.FrameDetails;
      reader.readMessage(value,proto.skills.FrameDetails.deserializeBinaryFromReader);
      msg.setFrameDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.PositionXY.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.PositionXY.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.PositionXY} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PositionXY.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getFrame();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFrameDetails();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.skills.FrameDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional double x = 1;
 * @return {number}
 */
proto.skills.PositionXY.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.PositionXY} returns this
 */
proto.skills.PositionXY.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double y = 2;
 * @return {number}
 */
proto.skills.PositionXY.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.PositionXY} returns this
 */
proto.skills.PositionXY.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional FrameType.Enum frame = 3;
 * @return {!proto.skills.FrameType.Enum}
 */
proto.skills.PositionXY.prototype.getFrame = function() {
  return /** @type {!proto.skills.FrameType.Enum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.skills.FrameType.Enum} value
 * @return {!proto.skills.PositionXY} returns this
 */
proto.skills.PositionXY.prototype.setFrame = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional FrameDetails frame_details = 4;
 * @return {?proto.skills.FrameDetails}
 */
proto.skills.PositionXY.prototype.getFrameDetails = function() {
  return /** @type{?proto.skills.FrameDetails} */ (
    jspb.Message.getWrapperField(this, proto.skills.FrameDetails, 4));
};


/**
 * @param {?proto.skills.FrameDetails|undefined} value
 * @return {!proto.skills.PositionXY} returns this
*/
proto.skills.PositionXY.prototype.setFrameDetails = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.PositionXY} returns this
 */
proto.skills.PositionXY.prototype.clearFrameDetails = function() {
  return this.setFrameDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.PositionXY.prototype.hasFrameDetails = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.PositionZ.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.PositionZ.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.PositionZ} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PositionZ.toObject = function(includeInstance, msg) {
  var f, obj = {
value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
frame: jspb.Message.getFieldWithDefault(msg, 2, 0),
frameDetails: (f = msg.getFrameDetails()) && proto.skills.FrameDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.PositionZ}
 */
proto.skills.PositionZ.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.PositionZ;
  return proto.skills.PositionZ.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.PositionZ} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.PositionZ}
 */
proto.skills.PositionZ.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.skills.FrameType.Enum} */ (reader.readEnum());
      msg.setFrame(value);
      break;
    case 3:
      var value = new proto.skills.FrameDetails;
      reader.readMessage(value,proto.skills.FrameDetails.deserializeBinaryFromReader);
      msg.setFrameDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.PositionZ.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.PositionZ.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.PositionZ} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PositionZ.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getFrame();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFrameDetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skills.FrameDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional float value = 1;
 * @return {number}
 */
proto.skills.PositionZ.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.PositionZ} returns this
 */
proto.skills.PositionZ.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional FrameType.Enum frame = 2;
 * @return {!proto.skills.FrameType.Enum}
 */
proto.skills.PositionZ.prototype.getFrame = function() {
  return /** @type {!proto.skills.FrameType.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.skills.FrameType.Enum} value
 * @return {!proto.skills.PositionZ} returns this
 */
proto.skills.PositionZ.prototype.setFrame = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional FrameDetails frame_details = 3;
 * @return {?proto.skills.FrameDetails}
 */
proto.skills.PositionZ.prototype.getFrameDetails = function() {
  return /** @type{?proto.skills.FrameDetails} */ (
    jspb.Message.getWrapperField(this, proto.skills.FrameDetails, 3));
};


/**
 * @param {?proto.skills.FrameDetails|undefined} value
 * @return {!proto.skills.PositionZ} returns this
*/
proto.skills.PositionZ.prototype.setFrameDetails = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.PositionZ} returns this
 */
proto.skills.PositionZ.prototype.clearFrameDetails = function() {
  return this.setFrameDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.PositionZ.prototype.hasFrameDetails = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.Heading.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.Heading.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.Heading} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.Heading.toObject = function(includeInstance, msg) {
  var f, obj = {
value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
frame: jspb.Message.getFieldWithDefault(msg, 2, 0),
frameDetails: (f = msg.getFrameDetails()) && proto.skills.FrameDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.Heading}
 */
proto.skills.Heading.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.Heading;
  return proto.skills.Heading.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.Heading} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.Heading}
 */
proto.skills.Heading.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.skills.FrameType.Enum} */ (reader.readEnum());
      msg.setFrame(value);
      break;
    case 3:
      var value = new proto.skills.FrameDetails;
      reader.readMessage(value,proto.skills.FrameDetails.deserializeBinaryFromReader);
      msg.setFrameDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.Heading.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.Heading.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.Heading} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.Heading.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getFrame();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getFrameDetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skills.FrameDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional float value = 1;
 * @return {number}
 */
proto.skills.Heading.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.Heading} returns this
 */
proto.skills.Heading.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional FrameType.Enum frame = 2;
 * @return {!proto.skills.FrameType.Enum}
 */
proto.skills.Heading.prototype.getFrame = function() {
  return /** @type {!proto.skills.FrameType.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.skills.FrameType.Enum} value
 * @return {!proto.skills.Heading} returns this
 */
proto.skills.Heading.prototype.setFrame = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional FrameDetails frame_details = 3;
 * @return {?proto.skills.FrameDetails}
 */
proto.skills.Heading.prototype.getFrameDetails = function() {
  return /** @type{?proto.skills.FrameDetails} */ (
    jspb.Message.getWrapperField(this, proto.skills.FrameDetails, 3));
};


/**
 * @param {?proto.skills.FrameDetails|undefined} value
 * @return {!proto.skills.Heading} returns this
*/
proto.skills.Heading.prototype.setFrameDetails = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.Heading} returns this
 */
proto.skills.Heading.prototype.clearFrameDetails = function() {
  return this.setFrameDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.Heading.prototype.hasFrameDetails = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GimbalPitch.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GimbalPitch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GimbalPitch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GimbalPitch.toObject = function(includeInstance, msg) {
  var f, obj = {
value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GimbalPitch}
 */
proto.skills.GimbalPitch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GimbalPitch;
  return proto.skills.GimbalPitch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GimbalPitch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GimbalPitch}
 */
proto.skills.GimbalPitch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GimbalPitch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GimbalPitch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GimbalPitch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GimbalPitch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
};


/**
 * optional float value = 1;
 * @return {number}
 */
proto.skills.GimbalPitch.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.GimbalPitch} returns this
 */
proto.skills.GimbalPitch.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.HeadingRate.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.HeadingRate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.HeadingRate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.HeadingRate.toObject = function(includeInstance, msg) {
  var f, obj = {
value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
aggressiveness: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
maxHeadingRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.HeadingRate}
 */
proto.skills.HeadingRate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.HeadingRate;
  return proto.skills.HeadingRate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.HeadingRate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.HeadingRate}
 */
proto.skills.HeadingRate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAggressiveness(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMaxHeadingRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.HeadingRate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.HeadingRate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.HeadingRate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.HeadingRate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getAggressiveness();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getMaxHeadingRate();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional float value = 1;
 * @return {number}
 */
proto.skills.HeadingRate.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.HeadingRate} returns this
 */
proto.skills.HeadingRate.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float aggressiveness = 2;
 * @return {number}
 */
proto.skills.HeadingRate.prototype.getAggressiveness = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.HeadingRate} returns this
 */
proto.skills.HeadingRate.prototype.setAggressiveness = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float max_heading_rate = 3;
 * @return {number}
 */
proto.skills.HeadingRate.prototype.getMaxHeadingRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.HeadingRate} returns this
 */
proto.skills.HeadingRate.prototype.setMaxHeadingRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.LookAtCommand.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.LookAtCommand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.LookAtCommand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.LookAtCommand.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
heading: (f = msg.getHeading()) && proto.skills.Heading.toObject(includeInstance, f),
gimbalPitch: (f = msg.getGimbalPitch()) && proto.skills.GimbalPitch.toObject(includeInstance, f),
headingRate: (f = msg.getHeadingRate()) && proto.skills.HeadingRate.toObject(includeInstance, f),
source: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.LookAtCommand}
 */
proto.skills.LookAtCommand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.LookAtCommand;
  return proto.skills.LookAtCommand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.LookAtCommand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.LookAtCommand}
 */
proto.skills.LookAtCommand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new proto.skills.Heading;
      reader.readMessage(value,proto.skills.Heading.deserializeBinaryFromReader);
      msg.setHeading(value);
      break;
    case 3:
      var value = new proto.skills.GimbalPitch;
      reader.readMessage(value,proto.skills.GimbalPitch.deserializeBinaryFromReader);
      msg.setGimbalPitch(value);
      break;
    case 5:
      var value = new proto.skills.HeadingRate;
      reader.readMessage(value,proto.skills.HeadingRate.deserializeBinaryFromReader);
      msg.setHeadingRate(value);
      break;
    case 4:
      var value = /** @type {!proto.skills.GoToWaypointSource.Enum} */ (reader.readEnum());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.LookAtCommand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.LookAtCommand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.LookAtCommand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.LookAtCommand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getHeading();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skills.Heading.serializeBinaryToWriter
    );
  }
  f = message.getGimbalPitch();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skills.GimbalPitch.serializeBinaryToWriter
    );
  }
  f = message.getHeadingRate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.skills.HeadingRate.serializeBinaryToWriter
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skills.LookAtCommand.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.LookAtCommand} returns this
 */
proto.skills.LookAtCommand.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Heading heading = 2;
 * @return {?proto.skills.Heading}
 */
proto.skills.LookAtCommand.prototype.getHeading = function() {
  return /** @type{?proto.skills.Heading} */ (
    jspb.Message.getWrapperField(this, proto.skills.Heading, 2));
};


/**
 * @param {?proto.skills.Heading|undefined} value
 * @return {!proto.skills.LookAtCommand} returns this
*/
proto.skills.LookAtCommand.prototype.setHeading = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.LookAtCommand} returns this
 */
proto.skills.LookAtCommand.prototype.clearHeading = function() {
  return this.setHeading(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.LookAtCommand.prototype.hasHeading = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GimbalPitch gimbal_pitch = 3;
 * @return {?proto.skills.GimbalPitch}
 */
proto.skills.LookAtCommand.prototype.getGimbalPitch = function() {
  return /** @type{?proto.skills.GimbalPitch} */ (
    jspb.Message.getWrapperField(this, proto.skills.GimbalPitch, 3));
};


/**
 * @param {?proto.skills.GimbalPitch|undefined} value
 * @return {!proto.skills.LookAtCommand} returns this
*/
proto.skills.LookAtCommand.prototype.setGimbalPitch = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.LookAtCommand} returns this
 */
proto.skills.LookAtCommand.prototype.clearGimbalPitch = function() {
  return this.setGimbalPitch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.LookAtCommand.prototype.hasGimbalPitch = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional HeadingRate heading_rate = 5;
 * @return {?proto.skills.HeadingRate}
 */
proto.skills.LookAtCommand.prototype.getHeadingRate = function() {
  return /** @type{?proto.skills.HeadingRate} */ (
    jspb.Message.getWrapperField(this, proto.skills.HeadingRate, 5));
};


/**
 * @param {?proto.skills.HeadingRate|undefined} value
 * @return {!proto.skills.LookAtCommand} returns this
*/
proto.skills.LookAtCommand.prototype.setHeadingRate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.LookAtCommand} returns this
 */
proto.skills.LookAtCommand.prototype.clearHeadingRate = function() {
  return this.setHeadingRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.LookAtCommand.prototype.hasHeadingRate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional GoToWaypointSource.Enum source = 4;
 * @return {!proto.skills.GoToWaypointSource.Enum}
 */
proto.skills.LookAtCommand.prototype.getSource = function() {
  return /** @type {!proto.skills.GoToWaypointSource.Enum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.skills.GoToWaypointSource.Enum} value
 * @return {!proto.skills.LookAtCommand} returns this
 */
proto.skills.LookAtCommand.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.Waypoint.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.Waypoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.Waypoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.Waypoint.toObject = function(includeInstance, msg) {
  var f, obj = {
xy: (f = msg.getXy()) && proto.skills.PositionXY.toObject(includeInstance, f),
z: (f = msg.getZ()) && proto.skills.PositionZ.toObject(includeInstance, f),
heading: (f = msg.getHeading()) && proto.skills.Heading.toObject(includeInstance, f),
gimbalPitch: (f = msg.getGimbalPitch()) && proto.skills.GimbalPitch.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.Waypoint}
 */
proto.skills.Waypoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.Waypoint;
  return proto.skills.Waypoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.Waypoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.Waypoint}
 */
proto.skills.Waypoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.PositionXY;
      reader.readMessage(value,proto.skills.PositionXY.deserializeBinaryFromReader);
      msg.setXy(value);
      break;
    case 2:
      var value = new proto.skills.PositionZ;
      reader.readMessage(value,proto.skills.PositionZ.deserializeBinaryFromReader);
      msg.setZ(value);
      break;
    case 3:
      var value = new proto.skills.Heading;
      reader.readMessage(value,proto.skills.Heading.deserializeBinaryFromReader);
      msg.setHeading(value);
      break;
    case 4:
      var value = new proto.skills.GimbalPitch;
      reader.readMessage(value,proto.skills.GimbalPitch.deserializeBinaryFromReader);
      msg.setGimbalPitch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.Waypoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.Waypoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.Waypoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.Waypoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getXy();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.PositionXY.serializeBinaryToWriter
    );
  }
  f = message.getZ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skills.PositionZ.serializeBinaryToWriter
    );
  }
  f = message.getHeading();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skills.Heading.serializeBinaryToWriter
    );
  }
  f = message.getGimbalPitch();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.skills.GimbalPitch.serializeBinaryToWriter
    );
  }
};


/**
 * optional PositionXY xy = 1;
 * @return {?proto.skills.PositionXY}
 */
proto.skills.Waypoint.prototype.getXy = function() {
  return /** @type{?proto.skills.PositionXY} */ (
    jspb.Message.getWrapperField(this, proto.skills.PositionXY, 1));
};


/**
 * @param {?proto.skills.PositionXY|undefined} value
 * @return {!proto.skills.Waypoint} returns this
*/
proto.skills.Waypoint.prototype.setXy = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.Waypoint} returns this
 */
proto.skills.Waypoint.prototype.clearXy = function() {
  return this.setXy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.Waypoint.prototype.hasXy = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PositionZ z = 2;
 * @return {?proto.skills.PositionZ}
 */
proto.skills.Waypoint.prototype.getZ = function() {
  return /** @type{?proto.skills.PositionZ} */ (
    jspb.Message.getWrapperField(this, proto.skills.PositionZ, 2));
};


/**
 * @param {?proto.skills.PositionZ|undefined} value
 * @return {!proto.skills.Waypoint} returns this
*/
proto.skills.Waypoint.prototype.setZ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.Waypoint} returns this
 */
proto.skills.Waypoint.prototype.clearZ = function() {
  return this.setZ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.Waypoint.prototype.hasZ = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Heading heading = 3;
 * @return {?proto.skills.Heading}
 */
proto.skills.Waypoint.prototype.getHeading = function() {
  return /** @type{?proto.skills.Heading} */ (
    jspb.Message.getWrapperField(this, proto.skills.Heading, 3));
};


/**
 * @param {?proto.skills.Heading|undefined} value
 * @return {!proto.skills.Waypoint} returns this
*/
proto.skills.Waypoint.prototype.setHeading = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.Waypoint} returns this
 */
proto.skills.Waypoint.prototype.clearHeading = function() {
  return this.setHeading(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.Waypoint.prototype.hasHeading = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional GimbalPitch gimbal_pitch = 4;
 * @return {?proto.skills.GimbalPitch}
 */
proto.skills.Waypoint.prototype.getGimbalPitch = function() {
  return /** @type{?proto.skills.GimbalPitch} */ (
    jspb.Message.getWrapperField(this, proto.skills.GimbalPitch, 4));
};


/**
 * @param {?proto.skills.GimbalPitch|undefined} value
 * @return {!proto.skills.Waypoint} returns this
*/
proto.skills.Waypoint.prototype.setGimbalPitch = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.Waypoint} returns this
 */
proto.skills.Waypoint.prototype.clearGimbalPitch = function() {
  return this.setGimbalPitch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.Waypoint.prototype.hasGimbalPitch = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.PositionWaypoint.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.PositionWaypoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.PositionWaypoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PositionWaypoint.toObject = function(includeInstance, msg) {
  var f, obj = {
xy: (f = msg.getXy()) && proto.skills.PositionXY.toObject(includeInstance, f),
z: (f = msg.getZ()) && proto.skills.PositionZ.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.PositionWaypoint}
 */
proto.skills.PositionWaypoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.PositionWaypoint;
  return proto.skills.PositionWaypoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.PositionWaypoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.PositionWaypoint}
 */
proto.skills.PositionWaypoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.PositionXY;
      reader.readMessage(value,proto.skills.PositionXY.deserializeBinaryFromReader);
      msg.setXy(value);
      break;
    case 2:
      var value = new proto.skills.PositionZ;
      reader.readMessage(value,proto.skills.PositionZ.deserializeBinaryFromReader);
      msg.setZ(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.PositionWaypoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.PositionWaypoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.PositionWaypoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.PositionWaypoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getXy();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.PositionXY.serializeBinaryToWriter
    );
  }
  f = message.getZ();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skills.PositionZ.serializeBinaryToWriter
    );
  }
};


/**
 * optional PositionXY xy = 1;
 * @return {?proto.skills.PositionXY}
 */
proto.skills.PositionWaypoint.prototype.getXy = function() {
  return /** @type{?proto.skills.PositionXY} */ (
    jspb.Message.getWrapperField(this, proto.skills.PositionXY, 1));
};


/**
 * @param {?proto.skills.PositionXY|undefined} value
 * @return {!proto.skills.PositionWaypoint} returns this
*/
proto.skills.PositionWaypoint.prototype.setXy = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.PositionWaypoint} returns this
 */
proto.skills.PositionWaypoint.prototype.clearXy = function() {
  return this.setXy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.PositionWaypoint.prototype.hasXy = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PositionZ z = 2;
 * @return {?proto.skills.PositionZ}
 */
proto.skills.PositionWaypoint.prototype.getZ = function() {
  return /** @type{?proto.skills.PositionZ} */ (
    jspb.Message.getWrapperField(this, proto.skills.PositionZ, 2));
};


/**
 * @param {?proto.skills.PositionZ|undefined} value
 * @return {!proto.skills.PositionWaypoint} returns this
*/
proto.skills.PositionWaypoint.prototype.setZ = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.PositionWaypoint} returns this
 */
proto.skills.PositionWaypoint.prototype.clearZ = function() {
  return this.setZ(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.PositionWaypoint.prototype.hasZ = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.LookAtWaypoint.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.LookAtWaypoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.LookAtWaypoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.LookAtWaypoint.toObject = function(includeInstance, msg) {
  var f, obj = {
waypoint: (f = msg.getWaypoint()) && proto.skills.PositionWaypoint.toObject(includeInstance, f),
lookAtPoint: (f = msg.getLookAtPoint()) && proto.skills.PositionWaypoint.toObject(includeInstance, f),
useVerticalLookAtHeading: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
verticalLookAtHeading: (f = msg.getVerticalLookAtHeading()) && proto.skills.Heading.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.LookAtWaypoint}
 */
proto.skills.LookAtWaypoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.LookAtWaypoint;
  return proto.skills.LookAtWaypoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.LookAtWaypoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.LookAtWaypoint}
 */
proto.skills.LookAtWaypoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.PositionWaypoint;
      reader.readMessage(value,proto.skills.PositionWaypoint.deserializeBinaryFromReader);
      msg.setWaypoint(value);
      break;
    case 2:
      var value = new proto.skills.PositionWaypoint;
      reader.readMessage(value,proto.skills.PositionWaypoint.deserializeBinaryFromReader);
      msg.setLookAtPoint(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseVerticalLookAtHeading(value);
      break;
    case 4:
      var value = new proto.skills.Heading;
      reader.readMessage(value,proto.skills.Heading.deserializeBinaryFromReader);
      msg.setVerticalLookAtHeading(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.LookAtWaypoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.LookAtWaypoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.LookAtWaypoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.LookAtWaypoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWaypoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.PositionWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getLookAtPoint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skills.PositionWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getUseVerticalLookAtHeading();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getVerticalLookAtHeading();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.skills.Heading.serializeBinaryToWriter
    );
  }
};


/**
 * optional PositionWaypoint waypoint = 1;
 * @return {?proto.skills.PositionWaypoint}
 */
proto.skills.LookAtWaypoint.prototype.getWaypoint = function() {
  return /** @type{?proto.skills.PositionWaypoint} */ (
    jspb.Message.getWrapperField(this, proto.skills.PositionWaypoint, 1));
};


/**
 * @param {?proto.skills.PositionWaypoint|undefined} value
 * @return {!proto.skills.LookAtWaypoint} returns this
*/
proto.skills.LookAtWaypoint.prototype.setWaypoint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.LookAtWaypoint} returns this
 */
proto.skills.LookAtWaypoint.prototype.clearWaypoint = function() {
  return this.setWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.LookAtWaypoint.prototype.hasWaypoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PositionWaypoint look_at_point = 2;
 * @return {?proto.skills.PositionWaypoint}
 */
proto.skills.LookAtWaypoint.prototype.getLookAtPoint = function() {
  return /** @type{?proto.skills.PositionWaypoint} */ (
    jspb.Message.getWrapperField(this, proto.skills.PositionWaypoint, 2));
};


/**
 * @param {?proto.skills.PositionWaypoint|undefined} value
 * @return {!proto.skills.LookAtWaypoint} returns this
*/
proto.skills.LookAtWaypoint.prototype.setLookAtPoint = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.LookAtWaypoint} returns this
 */
proto.skills.LookAtWaypoint.prototype.clearLookAtPoint = function() {
  return this.setLookAtPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.LookAtWaypoint.prototype.hasLookAtPoint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool use_vertical_look_at_heading = 3;
 * @return {boolean}
 */
proto.skills.LookAtWaypoint.prototype.getUseVerticalLookAtHeading = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.LookAtWaypoint} returns this
 */
proto.skills.LookAtWaypoint.prototype.setUseVerticalLookAtHeading = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional Heading vertical_look_at_heading = 4;
 * @return {?proto.skills.Heading}
 */
proto.skills.LookAtWaypoint.prototype.getVerticalLookAtHeading = function() {
  return /** @type{?proto.skills.Heading} */ (
    jspb.Message.getWrapperField(this, proto.skills.Heading, 4));
};


/**
 * @param {?proto.skills.Heading|undefined} value
 * @return {!proto.skills.LookAtWaypoint} returns this
*/
proto.skills.LookAtWaypoint.prototype.setVerticalLookAtHeading = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.LookAtWaypoint} returns this
 */
proto.skills.LookAtWaypoint.prototype.clearVerticalLookAtHeading = function() {
  return this.setVerticalLookAtHeading(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.LookAtWaypoint.prototype.hasVerticalLookAtHeading = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.DockWaypointEstimate.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.DockWaypointEstimate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.DockWaypointEstimate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.DockWaypointEstimate.toObject = function(includeInstance, msg) {
  var f, obj = {
source: jspb.Message.getFieldWithDefault(msg, 1, 0),
dockWaypoint: (f = msg.getDockWaypoint()) && proto.skills.Waypoint.toObject(includeInstance, f),
uncertainty: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
navTWaypoint: (f = msg.getNavTWaypoint()) && pbtypes_gen_body_trans_pb.TransPb.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.DockWaypointEstimate}
 */
proto.skills.DockWaypointEstimate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.DockWaypointEstimate;
  return proto.skills.DockWaypointEstimate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.DockWaypointEstimate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.DockWaypointEstimate}
 */
proto.skills.DockWaypointEstimate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.waypoints.LandingPadAnchorPoseSource.Enum} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 2:
      var value = new proto.skills.Waypoint;
      reader.readMessage(value,proto.skills.Waypoint.deserializeBinaryFromReader);
      msg.setDockWaypoint(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUncertainty(value);
      break;
    case 4:
      var value = new pbtypes_gen_body_trans_pb.TransPb;
      reader.readMessage(value,pbtypes_gen_body_trans_pb.TransPb.deserializeBinaryFromReader);
      msg.setNavTWaypoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.DockWaypointEstimate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.DockWaypointEstimate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.DockWaypointEstimate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.DockWaypointEstimate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDockWaypoint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skills.Waypoint.serializeBinaryToWriter
    );
  }
  f = message.getUncertainty();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getNavTWaypoint();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      pbtypes_gen_body_trans_pb.TransPb.serializeBinaryToWriter
    );
  }
};


/**
 * optional waypoints.LandingPadAnchorPoseSource.Enum source = 1;
 * @return {!proto.waypoints.LandingPadAnchorPoseSource.Enum}
 */
proto.skills.DockWaypointEstimate.prototype.getSource = function() {
  return /** @type {!proto.waypoints.LandingPadAnchorPoseSource.Enum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.waypoints.LandingPadAnchorPoseSource.Enum} value
 * @return {!proto.skills.DockWaypointEstimate} returns this
 */
proto.skills.DockWaypointEstimate.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Waypoint dock_waypoint = 2;
 * @return {?proto.skills.Waypoint}
 */
proto.skills.DockWaypointEstimate.prototype.getDockWaypoint = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, proto.skills.Waypoint, 2));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.skills.DockWaypointEstimate} returns this
*/
proto.skills.DockWaypointEstimate.prototype.setDockWaypoint = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.DockWaypointEstimate} returns this
 */
proto.skills.DockWaypointEstimate.prototype.clearDockWaypoint = function() {
  return this.setDockWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.DockWaypointEstimate.prototype.hasDockWaypoint = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double uncertainty = 3;
 * @return {number}
 */
proto.skills.DockWaypointEstimate.prototype.getUncertainty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.DockWaypointEstimate} returns this
 */
proto.skills.DockWaypointEstimate.prototype.setUncertainty = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional body.TransPb nav_T_waypoint = 4;
 * @return {?proto.body.TransPb}
 */
proto.skills.DockWaypointEstimate.prototype.getNavTWaypoint = function() {
  return /** @type{?proto.body.TransPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_body_trans_pb.TransPb, 4));
};


/**
 * @param {?proto.body.TransPb|undefined} value
 * @return {!proto.skills.DockWaypointEstimate} returns this
*/
proto.skills.DockWaypointEstimate.prototype.setNavTWaypoint = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.DockWaypointEstimate} returns this
 */
proto.skills.DockWaypointEstimate.prototype.clearNavTWaypoint = function() {
  return this.setNavTWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.DockWaypointEstimate.prototype.hasNavTWaypoint = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.DockWaypointList.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.DockWaypointList.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.DockWaypointList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.DockWaypointList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.DockWaypointList.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
dockWaypointEstimatesList: jspb.Message.toObjectList(msg.getDockWaypointEstimatesList(),
    proto.skills.DockWaypointEstimate.toObject, includeInstance),
selectedSource: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.DockWaypointList}
 */
proto.skills.DockWaypointList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.DockWaypointList;
  return proto.skills.DockWaypointList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.DockWaypointList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.DockWaypointList}
 */
proto.skills.DockWaypointList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new proto.skills.DockWaypointEstimate;
      reader.readMessage(value,proto.skills.DockWaypointEstimate.deserializeBinaryFromReader);
      msg.addDockWaypointEstimates(value);
      break;
    case 3:
      var value = /** @type {!proto.waypoints.LandingPadAnchorPoseSource.Enum} */ (reader.readEnum());
      msg.setSelectedSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.DockWaypointList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.DockWaypointList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.DockWaypointList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.DockWaypointList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDockWaypointEstimatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.skills.DockWaypointEstimate.serializeBinaryToWriter
    );
  }
  f = message.getSelectedSource();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skills.DockWaypointList.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.DockWaypointList} returns this
 */
proto.skills.DockWaypointList.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated DockWaypointEstimate dock_waypoint_estimates = 2;
 * @return {!Array<!proto.skills.DockWaypointEstimate>}
 */
proto.skills.DockWaypointList.prototype.getDockWaypointEstimatesList = function() {
  return /** @type{!Array<!proto.skills.DockWaypointEstimate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.DockWaypointEstimate, 2));
};


/**
 * @param {!Array<!proto.skills.DockWaypointEstimate>} value
 * @return {!proto.skills.DockWaypointList} returns this
*/
proto.skills.DockWaypointList.prototype.setDockWaypointEstimatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.skills.DockWaypointEstimate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.DockWaypointEstimate}
 */
proto.skills.DockWaypointList.prototype.addDockWaypointEstimates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.skills.DockWaypointEstimate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.DockWaypointList} returns this
 */
proto.skills.DockWaypointList.prototype.clearDockWaypointEstimatesList = function() {
  return this.setDockWaypointEstimatesList([]);
};


/**
 * optional waypoints.LandingPadAnchorPoseSource.Enum selected_source = 3;
 * @return {!proto.waypoints.LandingPadAnchorPoseSource.Enum}
 */
proto.skills.DockWaypointList.prototype.getSelectedSource = function() {
  return /** @type {!proto.waypoints.LandingPadAnchorPoseSource.Enum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.waypoints.LandingPadAnchorPoseSource.Enum} value
 * @return {!proto.skills.DockWaypointList} returns this
 */
proto.skills.DockWaypointList.prototype.setSelectedSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.DistAndTimeToTargetWaypoint.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.DistAndTimeToTargetWaypoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.DistAndTimeToTargetWaypoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.DistAndTimeToTargetWaypoint.toObject = function(includeInstance, msg) {
  var f, obj = {
distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
time: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
motionArgs: (f = msg.getMotionArgs()) && proto.skills.MotionArgs.toObject(includeInstance, f),
flightEnergyRequired: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.DistAndTimeToTargetWaypoint}
 */
proto.skills.DistAndTimeToTargetWaypoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.DistAndTimeToTargetWaypoint;
  return proto.skills.DistAndTimeToTargetWaypoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.DistAndTimeToTargetWaypoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.DistAndTimeToTargetWaypoint}
 */
proto.skills.DistAndTimeToTargetWaypoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistance(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTime(value);
      break;
    case 3:
      var value = new proto.skills.MotionArgs;
      reader.readMessage(value,proto.skills.MotionArgs.deserializeBinaryFromReader);
      msg.setMotionArgs(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFlightEnergyRequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.DistAndTimeToTargetWaypoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.DistAndTimeToTargetWaypoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.DistAndTimeToTargetWaypoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.DistAndTimeToTargetWaypoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getTime();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getMotionArgs();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skills.MotionArgs.serializeBinaryToWriter
    );
  }
  f = message.getFlightEnergyRequired();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional float distance = 1;
 * @return {number}
 */
proto.skills.DistAndTimeToTargetWaypoint.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.DistAndTimeToTargetWaypoint} returns this
 */
proto.skills.DistAndTimeToTargetWaypoint.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float time = 2;
 * @return {number}
 */
proto.skills.DistAndTimeToTargetWaypoint.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.DistAndTimeToTargetWaypoint} returns this
 */
proto.skills.DistAndTimeToTargetWaypoint.prototype.setTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional MotionArgs motion_args = 3;
 * @return {?proto.skills.MotionArgs}
 */
proto.skills.DistAndTimeToTargetWaypoint.prototype.getMotionArgs = function() {
  return /** @type{?proto.skills.MotionArgs} */ (
    jspb.Message.getWrapperField(this, proto.skills.MotionArgs, 3));
};


/**
 * @param {?proto.skills.MotionArgs|undefined} value
 * @return {!proto.skills.DistAndTimeToTargetWaypoint} returns this
*/
proto.skills.DistAndTimeToTargetWaypoint.prototype.setMotionArgs = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.DistAndTimeToTargetWaypoint} returns this
 */
proto.skills.DistAndTimeToTargetWaypoint.prototype.clearMotionArgs = function() {
  return this.setMotionArgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.DistAndTimeToTargetWaypoint.prototype.hasMotionArgs = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float flight_energy_required = 4;
 * @return {number}
 */
proto.skills.DistAndTimeToTargetWaypoint.prototype.getFlightEnergyRequired = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.DistAndTimeToTargetWaypoint} returns this
 */
proto.skills.DistAndTimeToTargetWaypoint.prototype.setFlightEnergyRequired = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.TargetWaypointData.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.TargetWaypointData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.TargetWaypointData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TargetWaypointData.toObject = function(includeInstance, msg) {
  var f, obj = {
waypoint: (f = msg.getWaypoint()) && proto.skills.Waypoint.toObject(includeInstance, f),
utimeWhenRecorded: jspb.Message.getFieldWithDefault(msg, 2, 0),
euclideanDistance3d: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
euclideanDistance2d: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
returnDistAndTime: (f = msg.getReturnDistAndTime()) && proto.skills.DistAndTimeToTargetWaypoint.toObject(includeInstance, f),
isReachable: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.TargetWaypointData}
 */
proto.skills.TargetWaypointData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.TargetWaypointData;
  return proto.skills.TargetWaypointData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.TargetWaypointData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.TargetWaypointData}
 */
proto.skills.TargetWaypointData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.Waypoint;
      reader.readMessage(value,proto.skills.Waypoint.deserializeBinaryFromReader);
      msg.setWaypoint(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtimeWhenRecorded(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEuclideanDistance3d(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEuclideanDistance2d(value);
      break;
    case 5:
      var value = new proto.skills.DistAndTimeToTargetWaypoint;
      reader.readMessage(value,proto.skills.DistAndTimeToTargetWaypoint.deserializeBinaryFromReader);
      msg.setReturnDistAndTime(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReachable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.TargetWaypointData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.TargetWaypointData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.TargetWaypointData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TargetWaypointData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWaypoint();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.Waypoint.serializeBinaryToWriter
    );
  }
  f = message.getUtimeWhenRecorded();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEuclideanDistance3d();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getEuclideanDistance2d();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getReturnDistAndTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.skills.DistAndTimeToTargetWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getIsReachable();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional Waypoint waypoint = 1;
 * @return {?proto.skills.Waypoint}
 */
proto.skills.TargetWaypointData.prototype.getWaypoint = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, proto.skills.Waypoint, 1));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.skills.TargetWaypointData} returns this
*/
proto.skills.TargetWaypointData.prototype.setWaypoint = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.TargetWaypointData} returns this
 */
proto.skills.TargetWaypointData.prototype.clearWaypoint = function() {
  return this.setWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.TargetWaypointData.prototype.hasWaypoint = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 utime_when_recorded = 2;
 * @return {number}
 */
proto.skills.TargetWaypointData.prototype.getUtimeWhenRecorded = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.TargetWaypointData} returns this
 */
proto.skills.TargetWaypointData.prototype.setUtimeWhenRecorded = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double euclidean_distance_3d = 3;
 * @return {number}
 */
proto.skills.TargetWaypointData.prototype.getEuclideanDistance3d = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.TargetWaypointData} returns this
 */
proto.skills.TargetWaypointData.prototype.setEuclideanDistance3d = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double euclidean_distance_2d = 4;
 * @return {number}
 */
proto.skills.TargetWaypointData.prototype.getEuclideanDistance2d = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.TargetWaypointData} returns this
 */
proto.skills.TargetWaypointData.prototype.setEuclideanDistance2d = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional DistAndTimeToTargetWaypoint return_dist_and_time = 5;
 * @return {?proto.skills.DistAndTimeToTargetWaypoint}
 */
proto.skills.TargetWaypointData.prototype.getReturnDistAndTime = function() {
  return /** @type{?proto.skills.DistAndTimeToTargetWaypoint} */ (
    jspb.Message.getWrapperField(this, proto.skills.DistAndTimeToTargetWaypoint, 5));
};


/**
 * @param {?proto.skills.DistAndTimeToTargetWaypoint|undefined} value
 * @return {!proto.skills.TargetWaypointData} returns this
*/
proto.skills.TargetWaypointData.prototype.setReturnDistAndTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.TargetWaypointData} returns this
 */
proto.skills.TargetWaypointData.prototype.clearReturnDistAndTime = function() {
  return this.setReturnDistAndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.TargetWaypointData.prototype.hasReturnDistAndTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool is_reachable = 6;
 * @return {boolean}
 */
proto.skills.TargetWaypointData.prototype.getIsReachable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.TargetWaypointData} returns this
 */
proto.skills.TargetWaypointData.prototype.setIsReachable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.Pilot.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.Pilot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.Pilot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.Pilot.toObject = function(includeInstance, msg) {
  var f, obj = {
waypointKey: jspb.Message.getFieldWithDefault(msg, 1, 0),
clientType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.Pilot}
 */
proto.skills.Pilot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.Pilot;
  return proto.skills.Pilot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.Pilot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.Pilot}
 */
proto.skills.Pilot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWaypointKey(value);
      break;
    case 2:
      var value = /** @type {!proto.cloud_config.ClientType.Enum} */ (reader.readEnum());
      msg.setClientType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.Pilot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.Pilot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.Pilot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.Pilot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWaypointKey();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getClientType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 waypoint_key = 1;
 * @return {number}
 */
proto.skills.Pilot.prototype.getWaypointKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.Pilot} returns this
 */
proto.skills.Pilot.prototype.setWaypointKey = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional cloud_config.ClientType.Enum client_type = 2;
 * @return {!proto.cloud_config.ClientType.Enum}
 */
proto.skills.Pilot.prototype.getClientType = function() {
  return /** @type {!proto.cloud_config.ClientType.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.cloud_config.ClientType.Enum} value
 * @return {!proto.skills.Pilot} returns this
 */
proto.skills.Pilot.prototype.setClientType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.WaypointsList.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.WaypointsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.WaypointsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WaypointsList.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
waypointDataMap: (f = msg.getWaypointDataMap()) ? f.toObject(includeInstance, proto.skills.TargetWaypointData.toObject) : [],
pilot: (f = msg.getPilot()) && proto.skills.Pilot.toObject(includeInstance, f),
criticalRtxDestinationsMap: (f = msg.getCriticalRtxDestinationsMap()) ? f.toObject(includeInstance, undefined) : [],
loadedFromFile: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
backupLostCommsReturnHeadingNav: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.WaypointsList}
 */
proto.skills.WaypointsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.WaypointsList;
  return proto.skills.WaypointsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.WaypointsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.WaypointsList}
 */
proto.skills.WaypointsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 6:
      var value = msg.getWaypointDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt64, jspb.BinaryReader.prototype.readMessage, proto.skills.TargetWaypointData.deserializeBinaryFromReader, 0, new proto.skills.TargetWaypointData());
         });
      break;
    case 7:
      var value = new proto.skills.Pilot;
      reader.readMessage(value,proto.skills.Pilot.deserializeBinaryFromReader);
      msg.setPilot(value);
      break;
    case 8:
      var value = msg.getCriticalRtxDestinationsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt64, jspb.BinaryReader.prototype.readInt64, null, 0, 0);
         });
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLoadedFromFile(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBackupLostCommsReturnHeadingNav(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.WaypointsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.WaypointsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.WaypointsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WaypointsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWaypointDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeInt64, jspb.BinaryWriter.prototype.writeMessage, proto.skills.TargetWaypointData.serializeBinaryToWriter);
  }
  f = message.getPilot();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.skills.Pilot.serializeBinaryToWriter
    );
  }
  f = message.getCriticalRtxDestinationsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeInt64, jspb.BinaryWriter.prototype.writeInt64);
  }
  f = message.getLoadedFromFile();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getBackupLostCommsReturnHeadingNav();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skills.WaypointsList.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.WaypointsList} returns this
 */
proto.skills.WaypointsList.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * map<int64, TargetWaypointData> waypoint_data = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.skills.TargetWaypointData>}
 */
proto.skills.WaypointsList.prototype.getWaypointDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.skills.TargetWaypointData>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      proto.skills.TargetWaypointData));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skills.WaypointsList} returns this
 */
proto.skills.WaypointsList.prototype.clearWaypointDataMap = function() {
  this.getWaypointDataMap().clear();
  return this;
};


/**
 * optional Pilot pilot = 7;
 * @return {?proto.skills.Pilot}
 */
proto.skills.WaypointsList.prototype.getPilot = function() {
  return /** @type{?proto.skills.Pilot} */ (
    jspb.Message.getWrapperField(this, proto.skills.Pilot, 7));
};


/**
 * @param {?proto.skills.Pilot|undefined} value
 * @return {!proto.skills.WaypointsList} returns this
*/
proto.skills.WaypointsList.prototype.setPilot = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.WaypointsList} returns this
 */
proto.skills.WaypointsList.prototype.clearPilot = function() {
  return this.setPilot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.WaypointsList.prototype.hasPilot = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * map<int64, int64> critical_rtx_destinations = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.skills.WaypointsList.prototype.getCriticalRtxDestinationsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skills.WaypointsList} returns this
 */
proto.skills.WaypointsList.prototype.clearCriticalRtxDestinationsMap = function() {
  this.getCriticalRtxDestinationsMap().clear();
  return this;
};


/**
 * optional bool loaded_from_file = 9;
 * @return {boolean}
 */
proto.skills.WaypointsList.prototype.getLoadedFromFile = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.WaypointsList} returns this
 */
proto.skills.WaypointsList.prototype.setLoadedFromFile = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional float backup_lost_comms_return_heading_nav = 10;
 * @return {number}
 */
proto.skills.WaypointsList.prototype.getBackupLostCommsReturnHeadingNav = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.WaypointsList} returns this
 */
proto.skills.WaypointsList.prototype.setBackupLostCommsReturnHeadingNav = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.WaypointsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.WaypointsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.WaypointsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.WaypointsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WaypointsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
waypointsMap: (f = msg.getWaypointsMap()) ? f.toObject(includeInstance, proto.skills.Waypoint.toObject) : [],
keysToClearList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.WaypointsRequest}
 */
proto.skills.WaypointsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.WaypointsRequest;
  return proto.skills.WaypointsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.WaypointsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.WaypointsRequest}
 */
proto.skills.WaypointsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = msg.getWaypointsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt64, jspb.BinaryReader.prototype.readMessage, proto.skills.Waypoint.deserializeBinaryFromReader, 0, new proto.skills.Waypoint());
         });
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addKeysToClear(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.WaypointsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.WaypointsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.WaypointsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WaypointsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getWaypointsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeInt64, jspb.BinaryWriter.prototype.writeMessage, proto.skills.Waypoint.serializeBinaryToWriter);
  }
  f = message.getKeysToClearList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skills.WaypointsRequest.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.WaypointsRequest} returns this
 */
proto.skills.WaypointsRequest.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * map<int64, Waypoint> waypoints = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.skills.Waypoint>}
 */
proto.skills.WaypointsRequest.prototype.getWaypointsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.skills.Waypoint>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.skills.Waypoint));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.skills.WaypointsRequest} returns this
 */
proto.skills.WaypointsRequest.prototype.clearWaypointsMap = function() {
  this.getWaypointsMap().clear();
  return this;
};


/**
 * repeated int64 keys_to_clear = 3;
 * @return {!Array<number>}
 */
proto.skills.WaypointsRequest.prototype.getKeysToClearList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.skills.WaypointsRequest} returns this
 */
proto.skills.WaypointsRequest.prototype.setKeysToClearList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.skills.WaypointsRequest} returns this
 */
proto.skills.WaypointsRequest.prototype.addKeysToClear = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.WaypointsRequest} returns this
 */
proto.skills.WaypointsRequest.prototype.clearKeysToClearList = function() {
  return this.setKeysToClearList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.AnchorWaypoints.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.AnchorWaypoints.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.AnchorWaypoints} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.AnchorWaypoints.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
launch: (f = msg.getLaunch()) && proto.skills.Waypoint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.AnchorWaypoints}
 */
proto.skills.AnchorWaypoints.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.AnchorWaypoints;
  return proto.skills.AnchorWaypoints.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.AnchorWaypoints} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.AnchorWaypoints}
 */
proto.skills.AnchorWaypoints.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new proto.skills.Waypoint;
      reader.readMessage(value,proto.skills.Waypoint.deserializeBinaryFromReader);
      msg.setLaunch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.AnchorWaypoints.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.AnchorWaypoints.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.AnchorWaypoints} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.AnchorWaypoints.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLaunch();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skills.Waypoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skills.AnchorWaypoints.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.AnchorWaypoints} returns this
 */
proto.skills.AnchorWaypoints.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Waypoint launch = 2;
 * @return {?proto.skills.Waypoint}
 */
proto.skills.AnchorWaypoints.prototype.getLaunch = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, proto.skills.Waypoint, 2));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.skills.AnchorWaypoints} returns this
*/
proto.skills.AnchorWaypoints.prototype.setLaunch = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.AnchorWaypoints} returns this
 */
proto.skills.AnchorWaypoints.prototype.clearLaunch = function() {
  return this.setLaunch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.AnchorWaypoints.prototype.hasLaunch = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.Landmark.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.Landmark.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.Landmark} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.Landmark.toObject = function(includeInstance, msg) {
  var f, obj = {
label: jspb.Message.getFieldWithDefault(msg, 1, ""),
waypoint: (f = msg.getWaypoint()) && proto.skills.Waypoint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.Landmark}
 */
proto.skills.Landmark.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.Landmark;
  return proto.skills.Landmark.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.Landmark} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.Landmark}
 */
proto.skills.Landmark.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = new proto.skills.Waypoint;
      reader.readMessage(value,proto.skills.Waypoint.deserializeBinaryFromReader);
      msg.setWaypoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.Landmark.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.Landmark.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.Landmark} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.Landmark.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWaypoint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skills.Waypoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.skills.Landmark.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skills.Landmark} returns this
 */
proto.skills.Landmark.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Waypoint waypoint = 2;
 * @return {?proto.skills.Waypoint}
 */
proto.skills.Landmark.prototype.getWaypoint = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, proto.skills.Waypoint, 2));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.skills.Landmark} returns this
*/
proto.skills.Landmark.prototype.setWaypoint = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.Landmark} returns this
 */
proto.skills.Landmark.prototype.clearWaypoint = function() {
  return this.setWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.Landmark.prototype.hasWaypoint = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.LandmarkList.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.LandmarkList.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.LandmarkList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.LandmarkList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.LandmarkList.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
landmarksList: jspb.Message.toObjectList(msg.getLandmarksList(),
    proto.skills.Landmark.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.LandmarkList}
 */
proto.skills.LandmarkList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.LandmarkList;
  return proto.skills.LandmarkList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.LandmarkList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.LandmarkList}
 */
proto.skills.LandmarkList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new proto.skills.Landmark;
      reader.readMessage(value,proto.skills.Landmark.deserializeBinaryFromReader);
      msg.addLandmarks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.LandmarkList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.LandmarkList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.LandmarkList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.LandmarkList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLandmarksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.skills.Landmark.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skills.LandmarkList.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.LandmarkList} returns this
 */
proto.skills.LandmarkList.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Landmark landmarks = 2;
 * @return {!Array<!proto.skills.Landmark>}
 */
proto.skills.LandmarkList.prototype.getLandmarksList = function() {
  return /** @type{!Array<!proto.skills.Landmark>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.Landmark, 2));
};


/**
 * @param {!Array<!proto.skills.Landmark>} value
 * @return {!proto.skills.LandmarkList} returns this
*/
proto.skills.LandmarkList.prototype.setLandmarksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.skills.Landmark=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.Landmark}
 */
proto.skills.LandmarkList.prototype.addLandmarks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.skills.Landmark, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.LandmarkList} returns this
 */
proto.skills.LandmarkList.prototype.clearLandmarksList = function() {
  return this.setLandmarksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.HeightMode.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.HeightMode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.HeightMode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.HeightMode.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.HeightMode}
 */
proto.skills.HeightMode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.HeightMode;
  return proto.skills.HeightMode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.HeightMode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.HeightMode}
 */
proto.skills.HeightMode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.HeightMode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.HeightMode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.HeightMode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.HeightMode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.skills.HeightMode.Enum = {
  UNSPECIFIED: 0,
  GRADUAL: 1,
  CONSTANT_BEGINNING: 2,
  CONSTANT_END: 3,
  MAX: 4,
  CUSTOM: 5
};


/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.skills.TraversalMotionArgs.oneofGroups_ = [[6,7]];

/**
 * @enum {number}
 */
proto.skills.TraversalMotionArgs.HeightModeArgsCase = {
  HEIGHT_MODE_ARGS_NOT_SET: 0,
  MAX_ARGS: 6,
  CUSTOM_ARGS: 7
};

/**
 * @return {proto.skills.TraversalMotionArgs.HeightModeArgsCase}
 */
proto.skills.TraversalMotionArgs.prototype.getHeightModeArgsCase = function() {
  return /** @type {proto.skills.TraversalMotionArgs.HeightModeArgsCase} */(jspb.Message.computeOneofCase(this, proto.skills.TraversalMotionArgs.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.TraversalMotionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.TraversalMotionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.TraversalMotionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TraversalMotionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
ascendSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
speed: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
descendSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
ignoreWaypointZ: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
heightMode: jspb.Message.getFieldWithDefault(msg, 5, 0),
maxArgs: (f = msg.getMaxArgs()) && proto.skills.PositionZ.toObject(includeInstance, f),
customArgs: (f = msg.getCustomArgs()) && proto.skills.PositionZ.toObject(includeInstance, f),
usePathfinder: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.TraversalMotionArgs}
 */
proto.skills.TraversalMotionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.TraversalMotionArgs;
  return proto.skills.TraversalMotionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.TraversalMotionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.TraversalMotionArgs}
 */
proto.skills.TraversalMotionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAscendSpeed(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpeed(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDescendSpeed(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreWaypointZ(value);
      break;
    case 5:
      var value = /** @type {!proto.skills.HeightMode.Enum} */ (reader.readEnum());
      msg.setHeightMode(value);
      break;
    case 6:
      var value = new proto.skills.PositionZ;
      reader.readMessage(value,proto.skills.PositionZ.deserializeBinaryFromReader);
      msg.setMaxArgs(value);
      break;
    case 7:
      var value = new proto.skills.PositionZ;
      reader.readMessage(value,proto.skills.PositionZ.deserializeBinaryFromReader);
      msg.setCustomArgs(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsePathfinder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.TraversalMotionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.TraversalMotionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.TraversalMotionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TraversalMotionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAscendSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getDescendSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getIgnoreWaypointZ();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getHeightMode();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getMaxArgs();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.skills.PositionZ.serializeBinaryToWriter
    );
  }
  f = message.getCustomArgs();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.skills.PositionZ.serializeBinaryToWriter
    );
  }
  f = message.getUsePathfinder();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional float ascend_speed = 1;
 * @return {number}
 */
proto.skills.TraversalMotionArgs.prototype.getAscendSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.TraversalMotionArgs} returns this
 */
proto.skills.TraversalMotionArgs.prototype.setAscendSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float speed = 2;
 * @return {number}
 */
proto.skills.TraversalMotionArgs.prototype.getSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.TraversalMotionArgs} returns this
 */
proto.skills.TraversalMotionArgs.prototype.setSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float descend_speed = 3;
 * @return {number}
 */
proto.skills.TraversalMotionArgs.prototype.getDescendSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.TraversalMotionArgs} returns this
 */
proto.skills.TraversalMotionArgs.prototype.setDescendSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional bool ignore_waypoint_z = 4;
 * @return {boolean}
 */
proto.skills.TraversalMotionArgs.prototype.getIgnoreWaypointZ = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.TraversalMotionArgs} returns this
 */
proto.skills.TraversalMotionArgs.prototype.setIgnoreWaypointZ = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional HeightMode.Enum height_mode = 5;
 * @return {!proto.skills.HeightMode.Enum}
 */
proto.skills.TraversalMotionArgs.prototype.getHeightMode = function() {
  return /** @type {!proto.skills.HeightMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.skills.HeightMode.Enum} value
 * @return {!proto.skills.TraversalMotionArgs} returns this
 */
proto.skills.TraversalMotionArgs.prototype.setHeightMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional PositionZ max_args = 6;
 * @return {?proto.skills.PositionZ}
 */
proto.skills.TraversalMotionArgs.prototype.getMaxArgs = function() {
  return /** @type{?proto.skills.PositionZ} */ (
    jspb.Message.getWrapperField(this, proto.skills.PositionZ, 6));
};


/**
 * @param {?proto.skills.PositionZ|undefined} value
 * @return {!proto.skills.TraversalMotionArgs} returns this
*/
proto.skills.TraversalMotionArgs.prototype.setMaxArgs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.skills.TraversalMotionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.TraversalMotionArgs} returns this
 */
proto.skills.TraversalMotionArgs.prototype.clearMaxArgs = function() {
  return this.setMaxArgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.TraversalMotionArgs.prototype.hasMaxArgs = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PositionZ custom_args = 7;
 * @return {?proto.skills.PositionZ}
 */
proto.skills.TraversalMotionArgs.prototype.getCustomArgs = function() {
  return /** @type{?proto.skills.PositionZ} */ (
    jspb.Message.getWrapperField(this, proto.skills.PositionZ, 7));
};


/**
 * @param {?proto.skills.PositionZ|undefined} value
 * @return {!proto.skills.TraversalMotionArgs} returns this
*/
proto.skills.TraversalMotionArgs.prototype.setCustomArgs = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.skills.TraversalMotionArgs.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.TraversalMotionArgs} returns this
 */
proto.skills.TraversalMotionArgs.prototype.clearCustomArgs = function() {
  return this.setCustomArgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.TraversalMotionArgs.prototype.hasCustomArgs = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool use_pathfinder = 8;
 * @return {boolean}
 */
proto.skills.TraversalMotionArgs.prototype.getUsePathfinder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.TraversalMotionArgs} returns this
 */
proto.skills.TraversalMotionArgs.prototype.setUsePathfinder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.HeadingMode.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.HeadingMode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.HeadingMode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.HeadingMode.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.HeadingMode}
 */
proto.skills.HeadingMode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.HeadingMode;
  return proto.skills.HeadingMode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.HeadingMode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.HeadingMode}
 */
proto.skills.HeadingMode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.HeadingMode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.HeadingMode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.HeadingMode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.HeadingMode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.skills.HeadingMode.Enum = {
  UNSPECIFIED: 0,
  GRADUAL: 1,
  FORWARD: 2,
  BACKWARD: 3,
  CONSTANT_BEGINNING: 4,
  CONSTANT_END: 5,
  CUSTOM: 6,
  LOOKAHEAD: 7,
  LOOKAT_POINT: 8
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GimbalPitchMode.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GimbalPitchMode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GimbalPitchMode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GimbalPitchMode.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GimbalPitchMode}
 */
proto.skills.GimbalPitchMode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GimbalPitchMode;
  return proto.skills.GimbalPitchMode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GimbalPitchMode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GimbalPitchMode}
 */
proto.skills.GimbalPitchMode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GimbalPitchMode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GimbalPitchMode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GimbalPitchMode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GimbalPitchMode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.skills.GimbalPitchMode.Enum = {
  UNSPECIFIED: 0,
  GRADUAL: 1,
  CONSTANT_BEGINNING: 2,
  CONSTANT_END: 3,
  CUSTOM: 4,
  LOOKAHEAD: 5,
  LOOKAT_TARGET: 6,
  LOOKAT_POINT: 7
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.LookAtMotionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.LookAtMotionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.LookAtMotionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.LookAtMotionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
ignoreTargetHeading: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
headingMode: jspb.Message.getFieldWithDefault(msg, 2, 0),
customHeading: (f = msg.getCustomHeading()) && proto.skills.Heading.toObject(includeInstance, f),
ignoreTargetGimbalPitch: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
gimbalPitchMode: jspb.Message.getFieldWithDefault(msg, 4, 0),
customGimbalPitch: (f = msg.getCustomGimbalPitch()) && proto.skills.GimbalPitch.toObject(includeInstance, f),
lookatPoint: (f = msg.getLookatPoint()) && proto.skills.PositionXY.toObject(includeInstance, f),
lookatHeight: (f = msg.getLookatHeight()) && proto.skills.PositionZ.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.LookAtMotionArgs}
 */
proto.skills.LookAtMotionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.LookAtMotionArgs;
  return proto.skills.LookAtMotionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.LookAtMotionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.LookAtMotionArgs}
 */
proto.skills.LookAtMotionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreTargetHeading(value);
      break;
    case 2:
      var value = /** @type {!proto.skills.HeadingMode.Enum} */ (reader.readEnum());
      msg.setHeadingMode(value);
      break;
    case 5:
      var value = new proto.skills.Heading;
      reader.readMessage(value,proto.skills.Heading.deserializeBinaryFromReader);
      msg.setCustomHeading(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreTargetGimbalPitch(value);
      break;
    case 4:
      var value = /** @type {!proto.skills.GimbalPitchMode.Enum} */ (reader.readEnum());
      msg.setGimbalPitchMode(value);
      break;
    case 6:
      var value = new proto.skills.GimbalPitch;
      reader.readMessage(value,proto.skills.GimbalPitch.deserializeBinaryFromReader);
      msg.setCustomGimbalPitch(value);
      break;
    case 7:
      var value = new proto.skills.PositionXY;
      reader.readMessage(value,proto.skills.PositionXY.deserializeBinaryFromReader);
      msg.setLookatPoint(value);
      break;
    case 8:
      var value = new proto.skills.PositionZ;
      reader.readMessage(value,proto.skills.PositionZ.deserializeBinaryFromReader);
      msg.setLookatHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.LookAtMotionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.LookAtMotionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.LookAtMotionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.LookAtMotionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIgnoreTargetHeading();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getHeadingMode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCustomHeading();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.skills.Heading.serializeBinaryToWriter
    );
  }
  f = message.getIgnoreTargetGimbalPitch();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getGimbalPitchMode();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getCustomGimbalPitch();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.skills.GimbalPitch.serializeBinaryToWriter
    );
  }
  f = message.getLookatPoint();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.skills.PositionXY.serializeBinaryToWriter
    );
  }
  f = message.getLookatHeight();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.skills.PositionZ.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool ignore_target_heading = 1;
 * @return {boolean}
 */
proto.skills.LookAtMotionArgs.prototype.getIgnoreTargetHeading = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.LookAtMotionArgs} returns this
 */
proto.skills.LookAtMotionArgs.prototype.setIgnoreTargetHeading = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional HeadingMode.Enum heading_mode = 2;
 * @return {!proto.skills.HeadingMode.Enum}
 */
proto.skills.LookAtMotionArgs.prototype.getHeadingMode = function() {
  return /** @type {!proto.skills.HeadingMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.skills.HeadingMode.Enum} value
 * @return {!proto.skills.LookAtMotionArgs} returns this
 */
proto.skills.LookAtMotionArgs.prototype.setHeadingMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Heading custom_heading = 5;
 * @return {?proto.skills.Heading}
 */
proto.skills.LookAtMotionArgs.prototype.getCustomHeading = function() {
  return /** @type{?proto.skills.Heading} */ (
    jspb.Message.getWrapperField(this, proto.skills.Heading, 5));
};


/**
 * @param {?proto.skills.Heading|undefined} value
 * @return {!proto.skills.LookAtMotionArgs} returns this
*/
proto.skills.LookAtMotionArgs.prototype.setCustomHeading = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.LookAtMotionArgs} returns this
 */
proto.skills.LookAtMotionArgs.prototype.clearCustomHeading = function() {
  return this.setCustomHeading(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.LookAtMotionArgs.prototype.hasCustomHeading = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool ignore_target_gimbal_pitch = 3;
 * @return {boolean}
 */
proto.skills.LookAtMotionArgs.prototype.getIgnoreTargetGimbalPitch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.LookAtMotionArgs} returns this
 */
proto.skills.LookAtMotionArgs.prototype.setIgnoreTargetGimbalPitch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional GimbalPitchMode.Enum gimbal_pitch_mode = 4;
 * @return {!proto.skills.GimbalPitchMode.Enum}
 */
proto.skills.LookAtMotionArgs.prototype.getGimbalPitchMode = function() {
  return /** @type {!proto.skills.GimbalPitchMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.skills.GimbalPitchMode.Enum} value
 * @return {!proto.skills.LookAtMotionArgs} returns this
 */
proto.skills.LookAtMotionArgs.prototype.setGimbalPitchMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional GimbalPitch custom_gimbal_pitch = 6;
 * @return {?proto.skills.GimbalPitch}
 */
proto.skills.LookAtMotionArgs.prototype.getCustomGimbalPitch = function() {
  return /** @type{?proto.skills.GimbalPitch} */ (
    jspb.Message.getWrapperField(this, proto.skills.GimbalPitch, 6));
};


/**
 * @param {?proto.skills.GimbalPitch|undefined} value
 * @return {!proto.skills.LookAtMotionArgs} returns this
*/
proto.skills.LookAtMotionArgs.prototype.setCustomGimbalPitch = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.LookAtMotionArgs} returns this
 */
proto.skills.LookAtMotionArgs.prototype.clearCustomGimbalPitch = function() {
  return this.setCustomGimbalPitch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.LookAtMotionArgs.prototype.hasCustomGimbalPitch = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PositionXY lookat_point = 7;
 * @return {?proto.skills.PositionXY}
 */
proto.skills.LookAtMotionArgs.prototype.getLookatPoint = function() {
  return /** @type{?proto.skills.PositionXY} */ (
    jspb.Message.getWrapperField(this, proto.skills.PositionXY, 7));
};


/**
 * @param {?proto.skills.PositionXY|undefined} value
 * @return {!proto.skills.LookAtMotionArgs} returns this
*/
proto.skills.LookAtMotionArgs.prototype.setLookatPoint = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.LookAtMotionArgs} returns this
 */
proto.skills.LookAtMotionArgs.prototype.clearLookatPoint = function() {
  return this.setLookatPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.LookAtMotionArgs.prototype.hasLookatPoint = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional PositionZ lookat_height = 8;
 * @return {?proto.skills.PositionZ}
 */
proto.skills.LookAtMotionArgs.prototype.getLookatHeight = function() {
  return /** @type{?proto.skills.PositionZ} */ (
    jspb.Message.getWrapperField(this, proto.skills.PositionZ, 8));
};


/**
 * @param {?proto.skills.PositionZ|undefined} value
 * @return {!proto.skills.LookAtMotionArgs} returns this
*/
proto.skills.LookAtMotionArgs.prototype.setLookatHeight = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.LookAtMotionArgs} returns this
 */
proto.skills.LookAtMotionArgs.prototype.clearLookatHeight = function() {
  return this.setLookatHeight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.LookAtMotionArgs.prototype.hasLookatHeight = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.FreeLookMode.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.FreeLookMode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.FreeLookMode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.FreeLookMode.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.FreeLookMode}
 */
proto.skills.FreeLookMode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.FreeLookMode;
  return proto.skills.FreeLookMode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.FreeLookMode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.FreeLookMode}
 */
proto.skills.FreeLookMode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.FreeLookMode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.FreeLookMode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.FreeLookMode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.FreeLookMode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.skills.FreeLookMode.Enum = {
  UNKNOWN: 0,
  PAN_AND_TILT: 1,
  PAN_ONLY: 2,
  TILT_ONLY: 3,
  DISABLED: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.AllowedEdges.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.AllowedEdges.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.AllowedEdges} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.AllowedEdges.toObject = function(includeInstance, msg) {
  var f, obj = {
vehiclePaths: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
loadedPaths: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.AllowedEdges}
 */
proto.skills.AllowedEdges.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.AllowedEdges;
  return proto.skills.AllowedEdges.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.AllowedEdges} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.AllowedEdges}
 */
proto.skills.AllowedEdges.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVehiclePaths(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLoadedPaths(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.AllowedEdges.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.AllowedEdges.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.AllowedEdges} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.AllowedEdges.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehiclePaths();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getLoadedPaths();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool vehicle_paths = 1;
 * @return {boolean}
 */
proto.skills.AllowedEdges.prototype.getVehiclePaths = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.AllowedEdges} returns this
 */
proto.skills.AllowedEdges.prototype.setVehiclePaths = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool loaded_paths = 2;
 * @return {boolean}
 */
proto.skills.AllowedEdges.prototype.getLoadedPaths = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.AllowedEdges} returns this
 */
proto.skills.AllowedEdges.prototype.setLoadedPaths = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GraphMotionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GraphMotionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GraphMotionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GraphMotionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
speed: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
allowedEdges: (f = msg.getAllowedEdges()) && proto.skills.AllowedEdges.toObject(includeInstance, f),
useSpline: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
slowdownNearTarget: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GraphMotionArgs}
 */
proto.skills.GraphMotionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GraphMotionArgs;
  return proto.skills.GraphMotionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GraphMotionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GraphMotionArgs}
 */
proto.skills.GraphMotionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSpeed(value);
      break;
    case 2:
      var value = new proto.skills.AllowedEdges;
      reader.readMessage(value,proto.skills.AllowedEdges.deserializeBinaryFromReader);
      msg.setAllowedEdges(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseSpline(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSlowdownNearTarget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GraphMotionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GraphMotionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GraphMotionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GraphMotionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpeed();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getAllowedEdges();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skills.AllowedEdges.serializeBinaryToWriter
    );
  }
  f = message.getUseSpline();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSlowdownNearTarget();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional float speed = 1;
 * @return {number}
 */
proto.skills.GraphMotionArgs.prototype.getSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.GraphMotionArgs} returns this
 */
proto.skills.GraphMotionArgs.prototype.setSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional AllowedEdges allowed_edges = 2;
 * @return {?proto.skills.AllowedEdges}
 */
proto.skills.GraphMotionArgs.prototype.getAllowedEdges = function() {
  return /** @type{?proto.skills.AllowedEdges} */ (
    jspb.Message.getWrapperField(this, proto.skills.AllowedEdges, 2));
};


/**
 * @param {?proto.skills.AllowedEdges|undefined} value
 * @return {!proto.skills.GraphMotionArgs} returns this
*/
proto.skills.GraphMotionArgs.prototype.setAllowedEdges = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GraphMotionArgs} returns this
 */
proto.skills.GraphMotionArgs.prototype.clearAllowedEdges = function() {
  return this.setAllowedEdges(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GraphMotionArgs.prototype.hasAllowedEdges = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool use_spline = 3;
 * @return {boolean}
 */
proto.skills.GraphMotionArgs.prototype.getUseSpline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.GraphMotionArgs} returns this
 */
proto.skills.GraphMotionArgs.prototype.setUseSpline = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool slowdown_near_target = 4;
 * @return {boolean}
 */
proto.skills.GraphMotionArgs.prototype.getSlowdownNearTarget = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.GraphMotionArgs} returns this
 */
proto.skills.GraphMotionArgs.prototype.setSlowdownNearTarget = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.MotionArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.MotionArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.MotionArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MotionArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
traversalArgs: (f = msg.getTraversalArgs()) && proto.skills.TraversalMotionArgs.toObject(includeInstance, f),
lookAtArgs: (f = msg.getLookAtArgs()) && proto.skills.LookAtMotionArgs.toObject(includeInstance, f),
graphArgs: (f = msg.getGraphArgs()) && proto.skills.GraphMotionArgs.toObject(includeInstance, f),
terminationArgs: (f = msg.getTerminationArgs()) && proto.skills.WaypointTerminationArgs.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.MotionArgs}
 */
proto.skills.MotionArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.MotionArgs;
  return proto.skills.MotionArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.MotionArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.MotionArgs}
 */
proto.skills.MotionArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.TraversalMotionArgs;
      reader.readMessage(value,proto.skills.TraversalMotionArgs.deserializeBinaryFromReader);
      msg.setTraversalArgs(value);
      break;
    case 2:
      var value = new proto.skills.LookAtMotionArgs;
      reader.readMessage(value,proto.skills.LookAtMotionArgs.deserializeBinaryFromReader);
      msg.setLookAtArgs(value);
      break;
    case 3:
      var value = new proto.skills.GraphMotionArgs;
      reader.readMessage(value,proto.skills.GraphMotionArgs.deserializeBinaryFromReader);
      msg.setGraphArgs(value);
      break;
    case 4:
      var value = new proto.skills.WaypointTerminationArgs;
      reader.readMessage(value,proto.skills.WaypointTerminationArgs.deserializeBinaryFromReader);
      msg.setTerminationArgs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.MotionArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.MotionArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.MotionArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.MotionArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTraversalArgs();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.TraversalMotionArgs.serializeBinaryToWriter
    );
  }
  f = message.getLookAtArgs();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skills.LookAtMotionArgs.serializeBinaryToWriter
    );
  }
  f = message.getGraphArgs();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skills.GraphMotionArgs.serializeBinaryToWriter
    );
  }
  f = message.getTerminationArgs();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.skills.WaypointTerminationArgs.serializeBinaryToWriter
    );
  }
};


/**
 * optional TraversalMotionArgs traversal_args = 1;
 * @return {?proto.skills.TraversalMotionArgs}
 */
proto.skills.MotionArgs.prototype.getTraversalArgs = function() {
  return /** @type{?proto.skills.TraversalMotionArgs} */ (
    jspb.Message.getWrapperField(this, proto.skills.TraversalMotionArgs, 1));
};


/**
 * @param {?proto.skills.TraversalMotionArgs|undefined} value
 * @return {!proto.skills.MotionArgs} returns this
*/
proto.skills.MotionArgs.prototype.setTraversalArgs = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MotionArgs} returns this
 */
proto.skills.MotionArgs.prototype.clearTraversalArgs = function() {
  return this.setTraversalArgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MotionArgs.prototype.hasTraversalArgs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LookAtMotionArgs look_at_args = 2;
 * @return {?proto.skills.LookAtMotionArgs}
 */
proto.skills.MotionArgs.prototype.getLookAtArgs = function() {
  return /** @type{?proto.skills.LookAtMotionArgs} */ (
    jspb.Message.getWrapperField(this, proto.skills.LookAtMotionArgs, 2));
};


/**
 * @param {?proto.skills.LookAtMotionArgs|undefined} value
 * @return {!proto.skills.MotionArgs} returns this
*/
proto.skills.MotionArgs.prototype.setLookAtArgs = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MotionArgs} returns this
 */
proto.skills.MotionArgs.prototype.clearLookAtArgs = function() {
  return this.setLookAtArgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MotionArgs.prototype.hasLookAtArgs = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GraphMotionArgs graph_args = 3;
 * @return {?proto.skills.GraphMotionArgs}
 */
proto.skills.MotionArgs.prototype.getGraphArgs = function() {
  return /** @type{?proto.skills.GraphMotionArgs} */ (
    jspb.Message.getWrapperField(this, proto.skills.GraphMotionArgs, 3));
};


/**
 * @param {?proto.skills.GraphMotionArgs|undefined} value
 * @return {!proto.skills.MotionArgs} returns this
*/
proto.skills.MotionArgs.prototype.setGraphArgs = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MotionArgs} returns this
 */
proto.skills.MotionArgs.prototype.clearGraphArgs = function() {
  return this.setGraphArgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MotionArgs.prototype.hasGraphArgs = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional WaypointTerminationArgs termination_args = 4;
 * @return {?proto.skills.WaypointTerminationArgs}
 */
proto.skills.MotionArgs.prototype.getTerminationArgs = function() {
  return /** @type{?proto.skills.WaypointTerminationArgs} */ (
    jspb.Message.getWrapperField(this, proto.skills.WaypointTerminationArgs, 4));
};


/**
 * @param {?proto.skills.WaypointTerminationArgs|undefined} value
 * @return {!proto.skills.MotionArgs} returns this
*/
proto.skills.MotionArgs.prototype.setTerminationArgs = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.MotionArgs} returns this
 */
proto.skills.MotionArgs.prototype.clearTerminationArgs = function() {
  return this.setTerminationArgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.MotionArgs.prototype.hasTerminationArgs = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.WaypointTerminationArgs.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.WaypointTerminationArgs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.WaypointTerminationArgs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WaypointTerminationArgs.toObject = function(includeInstance, msg) {
  var f, obj = {
xyPositionThreshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
zPositionThreshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.WaypointTerminationArgs}
 */
proto.skills.WaypointTerminationArgs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.WaypointTerminationArgs;
  return proto.skills.WaypointTerminationArgs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.WaypointTerminationArgs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.WaypointTerminationArgs}
 */
proto.skills.WaypointTerminationArgs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setXyPositionThreshold(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setZPositionThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.WaypointTerminationArgs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.WaypointTerminationArgs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.WaypointTerminationArgs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WaypointTerminationArgs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getXyPositionThreshold();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getZPositionThreshold();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float xy_position_threshold = 1;
 * @return {number}
 */
proto.skills.WaypointTerminationArgs.prototype.getXyPositionThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.WaypointTerminationArgs} returns this
 */
proto.skills.WaypointTerminationArgs.prototype.setXyPositionThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float z_position_threshold = 2;
 * @return {number}
 */
proto.skills.WaypointTerminationArgs.prototype.getZPositionThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.WaypointTerminationArgs} returns this
 */
proto.skills.WaypointTerminationArgs.prototype.setZPositionThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.WaypointKey.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.WaypointKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.WaypointKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WaypointKey.toObject = function(includeInstance, msg) {
  var f, obj = {
value: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.WaypointKey}
 */
proto.skills.WaypointKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.WaypointKey;
  return proto.skills.WaypointKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.WaypointKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.WaypointKey}
 */
proto.skills.WaypointKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.WaypointKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.WaypointKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.WaypointKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WaypointKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 value = 1;
 * @return {number}
 */
proto.skills.WaypointKey.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.WaypointKey} returns this
 */
proto.skills.WaypointKey.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.skills.TargetWaypoint.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.skills.TargetWaypoint.TargetWaypointCase = {
  TARGET_WAYPOINT_NOT_SET: 0,
  KEY: 1,
  WAYPOINT: 2
};

/**
 * @return {proto.skills.TargetWaypoint.TargetWaypointCase}
 */
proto.skills.TargetWaypoint.prototype.getTargetWaypointCase = function() {
  return /** @type {proto.skills.TargetWaypoint.TargetWaypointCase} */(jspb.Message.computeOneofCase(this, proto.skills.TargetWaypoint.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.TargetWaypoint.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.TargetWaypoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.TargetWaypoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TargetWaypoint.toObject = function(includeInstance, msg) {
  var f, obj = {
key: (f = msg.getKey()) && proto.skills.WaypointKey.toObject(includeInstance, f),
waypoint: (f = msg.getWaypoint()) && proto.skills.Waypoint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.TargetWaypoint}
 */
proto.skills.TargetWaypoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.TargetWaypoint;
  return proto.skills.TargetWaypoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.TargetWaypoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.TargetWaypoint}
 */
proto.skills.TargetWaypoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.WaypointKey;
      reader.readMessage(value,proto.skills.WaypointKey.deserializeBinaryFromReader);
      msg.setKey(value);
      break;
    case 2:
      var value = new proto.skills.Waypoint;
      reader.readMessage(value,proto.skills.Waypoint.deserializeBinaryFromReader);
      msg.setWaypoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.TargetWaypoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.TargetWaypoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.TargetWaypoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TargetWaypoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.WaypointKey.serializeBinaryToWriter
    );
  }
  f = message.getWaypoint();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skills.Waypoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional WaypointKey key = 1;
 * @return {?proto.skills.WaypointKey}
 */
proto.skills.TargetWaypoint.prototype.getKey = function() {
  return /** @type{?proto.skills.WaypointKey} */ (
    jspb.Message.getWrapperField(this, proto.skills.WaypointKey, 1));
};


/**
 * @param {?proto.skills.WaypointKey|undefined} value
 * @return {!proto.skills.TargetWaypoint} returns this
*/
proto.skills.TargetWaypoint.prototype.setKey = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.skills.TargetWaypoint.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.TargetWaypoint} returns this
 */
proto.skills.TargetWaypoint.prototype.clearKey = function() {
  return this.setKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.TargetWaypoint.prototype.hasKey = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Waypoint waypoint = 2;
 * @return {?proto.skills.Waypoint}
 */
proto.skills.TargetWaypoint.prototype.getWaypoint = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, proto.skills.Waypoint, 2));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.skills.TargetWaypoint} returns this
*/
proto.skills.TargetWaypoint.prototype.setWaypoint = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.skills.TargetWaypoint.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.TargetWaypoint} returns this
 */
proto.skills.TargetWaypoint.prototype.clearWaypoint = function() {
  return this.setWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.TargetWaypoint.prototype.hasWaypoint = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GoToWaypointRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GoToWaypointRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GoToWaypointRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GoToWaypointRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
nonce: jspb.Message.getFieldWithDefault(msg, 8, 0),
startFlying: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
target: (f = msg.getTarget()) && proto.skills.TargetWaypoint.toObject(includeInstance, f),
motionArgs: (f = msg.getMotionArgs()) && proto.skills.MotionArgs.toObject(includeInstance, f),
source: jspb.Message.getFieldWithDefault(msg, 5, 0),
landOnComplete: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
priority: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GoToWaypointRequest}
 */
proto.skills.GoToWaypointRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GoToWaypointRequest;
  return proto.skills.GoToWaypointRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GoToWaypointRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GoToWaypointRequest}
 */
proto.skills.GoToWaypointRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNonce(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStartFlying(value);
      break;
    case 3:
      var value = new proto.skills.TargetWaypoint;
      reader.readMessage(value,proto.skills.TargetWaypoint.deserializeBinaryFromReader);
      msg.setTarget(value);
      break;
    case 4:
      var value = new proto.skills.MotionArgs;
      reader.readMessage(value,proto.skills.MotionArgs.deserializeBinaryFromReader);
      msg.setMotionArgs(value);
      break;
    case 5:
      var value = /** @type {!proto.skills.GoToWaypointSource.Enum} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLandOnComplete(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPriority(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GoToWaypointRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GoToWaypointRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GoToWaypointRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GoToWaypointRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNonce();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getStartFlying();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getTarget();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skills.TargetWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getMotionArgs();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.skills.MotionArgs.serializeBinaryToWriter
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getLandOnComplete();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPriority();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skills.GoToWaypointRequest.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.GoToWaypointRequest} returns this
 */
proto.skills.GoToWaypointRequest.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 nonce = 8;
 * @return {number}
 */
proto.skills.GoToWaypointRequest.prototype.getNonce = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.GoToWaypointRequest} returns this
 */
proto.skills.GoToWaypointRequest.prototype.setNonce = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool start_flying = 2;
 * @return {boolean}
 */
proto.skills.GoToWaypointRequest.prototype.getStartFlying = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.GoToWaypointRequest} returns this
 */
proto.skills.GoToWaypointRequest.prototype.setStartFlying = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional TargetWaypoint target = 3;
 * @return {?proto.skills.TargetWaypoint}
 */
proto.skills.GoToWaypointRequest.prototype.getTarget = function() {
  return /** @type{?proto.skills.TargetWaypoint} */ (
    jspb.Message.getWrapperField(this, proto.skills.TargetWaypoint, 3));
};


/**
 * @param {?proto.skills.TargetWaypoint|undefined} value
 * @return {!proto.skills.GoToWaypointRequest} returns this
*/
proto.skills.GoToWaypointRequest.prototype.setTarget = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GoToWaypointRequest} returns this
 */
proto.skills.GoToWaypointRequest.prototype.clearTarget = function() {
  return this.setTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GoToWaypointRequest.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional MotionArgs motion_args = 4;
 * @return {?proto.skills.MotionArgs}
 */
proto.skills.GoToWaypointRequest.prototype.getMotionArgs = function() {
  return /** @type{?proto.skills.MotionArgs} */ (
    jspb.Message.getWrapperField(this, proto.skills.MotionArgs, 4));
};


/**
 * @param {?proto.skills.MotionArgs|undefined} value
 * @return {!proto.skills.GoToWaypointRequest} returns this
*/
proto.skills.GoToWaypointRequest.prototype.setMotionArgs = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GoToWaypointRequest} returns this
 */
proto.skills.GoToWaypointRequest.prototype.clearMotionArgs = function() {
  return this.setMotionArgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GoToWaypointRequest.prototype.hasMotionArgs = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional GoToWaypointSource.Enum source = 5;
 * @return {!proto.skills.GoToWaypointSource.Enum}
 */
proto.skills.GoToWaypointRequest.prototype.getSource = function() {
  return /** @type {!proto.skills.GoToWaypointSource.Enum} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.skills.GoToWaypointSource.Enum} value
 * @return {!proto.skills.GoToWaypointRequest} returns this
 */
proto.skills.GoToWaypointRequest.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool land_on_complete = 6;
 * @return {boolean}
 */
proto.skills.GoToWaypointRequest.prototype.getLandOnComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.GoToWaypointRequest} returns this
 */
proto.skills.GoToWaypointRequest.prototype.setLandOnComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional uint32 priority = 7;
 * @return {number}
 */
proto.skills.GoToWaypointRequest.prototype.getPriority = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.GoToWaypointRequest} returns this
 */
proto.skills.GoToWaypointRequest.prototype.setPriority = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GoToWaypointState.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GoToWaypointState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GoToWaypointState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GoToWaypointState.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GoToWaypointState}
 */
proto.skills.GoToWaypointState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GoToWaypointState;
  return proto.skills.GoToWaypointState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GoToWaypointState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GoToWaypointState}
 */
proto.skills.GoToWaypointState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GoToWaypointState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GoToWaypointState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GoToWaypointState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GoToWaypointState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.skills.GoToWaypointState.Enum = {
  UNSPECIFIED: 0,
  INACTIVE: 1,
  PENDING: 2,
  IN_PROGRESS: 3,
  CAMERA_ANGLE_ADJUSTMENT: 4,
  FINAL_ADJUSTMENT: 7,
  DONE: 5,
  FAILED: 6
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.DistanceSegments.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.DistanceSegments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.DistanceSegments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.DistanceSegments.toObject = function(includeInstance, msg) {
  var f, obj = {
upDistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
traverseDistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
downDistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.DistanceSegments}
 */
proto.skills.DistanceSegments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.DistanceSegments;
  return proto.skills.DistanceSegments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.DistanceSegments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.DistanceSegments}
 */
proto.skills.DistanceSegments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUpDistance(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTraverseDistance(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDownDistance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.DistanceSegments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.DistanceSegments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.DistanceSegments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.DistanceSegments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpDistance();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTraverseDistance();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getDownDistance();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double up_distance = 1;
 * @return {number}
 */
proto.skills.DistanceSegments.prototype.getUpDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.DistanceSegments} returns this
 */
proto.skills.DistanceSegments.prototype.setUpDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double traverse_distance = 2;
 * @return {number}
 */
proto.skills.DistanceSegments.prototype.getTraverseDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.DistanceSegments} returns this
 */
proto.skills.DistanceSegments.prototype.setTraverseDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double down_distance = 3;
 * @return {number}
 */
proto.skills.DistanceSegments.prototype.getDownDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.DistanceSegments} returns this
 */
proto.skills.DistanceSegments.prototype.setDownDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.DisplacementSegments.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.DisplacementSegments.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.DisplacementSegments.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.DisplacementSegments} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.DisplacementSegments.toObject = function(includeInstance, msg) {
  var f, obj = {
upDisplacement: (f = msg.getUpDisplacement()) && pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.toObject(includeInstance, f),
traverseDisplacementsList: jspb.Message.toObjectList(msg.getTraverseDisplacementsList(),
    pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.toObject, includeInstance),
downDisplacement: (f = msg.getDownDisplacement()) && pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.DisplacementSegments}
 */
proto.skills.DisplacementSegments.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.DisplacementSegments;
  return proto.skills.DisplacementSegments.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.DisplacementSegments} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.DisplacementSegments}
 */
proto.skills.DisplacementSegments.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.deserializeBinaryFromReader);
      msg.setUpDisplacement(value);
      break;
    case 2:
      var value = new pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.deserializeBinaryFromReader);
      msg.addTraverseDisplacements(value);
      break;
    case 3:
      var value = new pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb;
      reader.readMessage(value,pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.deserializeBinaryFromReader);
      msg.setDownDisplacement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.DisplacementSegments.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.DisplacementSegments.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.DisplacementSegments} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.DisplacementSegments.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpDisplacement();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.serializeBinaryToWriter
    );
  }
  f = message.getTraverseDisplacementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.serializeBinaryToWriter
    );
  }
  f = message.getDownDisplacement();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb.serializeBinaryToWriter
    );
  }
};


/**
 * optional eigen_lcm.Vector3dPb up_displacement = 1;
 * @return {?proto.eigen_lcm.Vector3dPb}
 */
proto.skills.DisplacementSegments.prototype.getUpDisplacement = function() {
  return /** @type{?proto.eigen_lcm.Vector3dPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb, 1));
};


/**
 * @param {?proto.eigen_lcm.Vector3dPb|undefined} value
 * @return {!proto.skills.DisplacementSegments} returns this
*/
proto.skills.DisplacementSegments.prototype.setUpDisplacement = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.DisplacementSegments} returns this
 */
proto.skills.DisplacementSegments.prototype.clearUpDisplacement = function() {
  return this.setUpDisplacement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.DisplacementSegments.prototype.hasUpDisplacement = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated eigen_lcm.Vector3dPb traverse_displacements = 2;
 * @return {!Array<!proto.eigen_lcm.Vector3dPb>}
 */
proto.skills.DisplacementSegments.prototype.getTraverseDisplacementsList = function() {
  return /** @type{!Array<!proto.eigen_lcm.Vector3dPb>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb, 2));
};


/**
 * @param {!Array<!proto.eigen_lcm.Vector3dPb>} value
 * @return {!proto.skills.DisplacementSegments} returns this
*/
proto.skills.DisplacementSegments.prototype.setTraverseDisplacementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.eigen_lcm.Vector3dPb=} opt_value
 * @param {number=} opt_index
 * @return {!proto.eigen_lcm.Vector3dPb}
 */
proto.skills.DisplacementSegments.prototype.addTraverseDisplacements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.eigen_lcm.Vector3dPb, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.DisplacementSegments} returns this
 */
proto.skills.DisplacementSegments.prototype.clearTraverseDisplacementsList = function() {
  return this.setTraverseDisplacementsList([]);
};


/**
 * optional eigen_lcm.Vector3dPb down_displacement = 3;
 * @return {?proto.eigen_lcm.Vector3dPb}
 */
proto.skills.DisplacementSegments.prototype.getDownDisplacement = function() {
  return /** @type{?proto.eigen_lcm.Vector3dPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_eigen_lcm_Vector3d_pb.Vector3dPb, 3));
};


/**
 * @param {?proto.eigen_lcm.Vector3dPb|undefined} value
 * @return {!proto.skills.DisplacementSegments} returns this
*/
proto.skills.DisplacementSegments.prototype.setDownDisplacement = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.DisplacementSegments} returns this
 */
proto.skills.DisplacementSegments.prototype.clearDownDisplacement = function() {
  return this.setDownDisplacement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.DisplacementSegments.prototype.hasDownDisplacement = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.SegmentEstimates.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.SegmentEstimates.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.SegmentEstimates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.SegmentEstimates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SegmentEstimates.toObject = function(includeInstance, msg) {
  var f, obj = {
traversalSegmentsList: jspb.Message.toObjectList(msg.getTraversalSegmentsList(),
    proto.skills.SegmentEstimate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.SegmentEstimates}
 */
proto.skills.SegmentEstimates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.SegmentEstimates;
  return proto.skills.SegmentEstimates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.SegmentEstimates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.SegmentEstimates}
 */
proto.skills.SegmentEstimates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.SegmentEstimate;
      reader.readMessage(value,proto.skills.SegmentEstimate.deserializeBinaryFromReader);
      msg.addTraversalSegments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.SegmentEstimates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.SegmentEstimates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.SegmentEstimates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SegmentEstimates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTraversalSegmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.skills.SegmentEstimate.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SegmentEstimate traversal_segments = 1;
 * @return {!Array<!proto.skills.SegmentEstimate>}
 */
proto.skills.SegmentEstimates.prototype.getTraversalSegmentsList = function() {
  return /** @type{!Array<!proto.skills.SegmentEstimate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.SegmentEstimate, 1));
};


/**
 * @param {!Array<!proto.skills.SegmentEstimate>} value
 * @return {!proto.skills.SegmentEstimates} returns this
*/
proto.skills.SegmentEstimates.prototype.setTraversalSegmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.skills.SegmentEstimate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.SegmentEstimate}
 */
proto.skills.SegmentEstimates.prototype.addTraversalSegments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.skills.SegmentEstimate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.SegmentEstimates} returns this
 */
proto.skills.SegmentEstimates.prototype.clearTraversalSegmentsList = function() {
  return this.setTraversalSegmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.SegmentEstimate.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.SegmentEstimate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.SegmentEstimate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SegmentEstimate.toObject = function(includeInstance, msg) {
  var f, obj = {
distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
time: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
groundSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
airSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
energy: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.SegmentEstimate}
 */
proto.skills.SegmentEstimate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.SegmentEstimate;
  return proto.skills.SegmentEstimate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.SegmentEstimate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.SegmentEstimate}
 */
proto.skills.SegmentEstimate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDistance(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGroundSpeed(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAirSpeed(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setEnergy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.SegmentEstimate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.SegmentEstimate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.SegmentEstimate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SegmentEstimate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTime();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getGroundSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getAirSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getEnergy();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional double distance = 1;
 * @return {number}
 */
proto.skills.SegmentEstimate.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SegmentEstimate} returns this
 */
proto.skills.SegmentEstimate.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double time = 2;
 * @return {number}
 */
proto.skills.SegmentEstimate.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SegmentEstimate} returns this
 */
proto.skills.SegmentEstimate.prototype.setTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double ground_speed = 3;
 * @return {number}
 */
proto.skills.SegmentEstimate.prototype.getGroundSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SegmentEstimate} returns this
 */
proto.skills.SegmentEstimate.prototype.setGroundSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double air_speed = 4;
 * @return {number}
 */
proto.skills.SegmentEstimate.prototype.getAirSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SegmentEstimate} returns this
 */
proto.skills.SegmentEstimate.prototype.setAirSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double energy = 5;
 * @return {number}
 */
proto.skills.SegmentEstimate.prototype.getEnergy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SegmentEstimate} returns this
 */
proto.skills.SegmentEstimate.prototype.setEnergy = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.HasIntermediateGoals.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.HasIntermediateGoals.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.HasIntermediateGoals} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.HasIntermediateGoals.toObject = function(includeInstance, msg) {
  var f, obj = {
hasUpGoal: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
hasTraverseGoal: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
hasDownGoal: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.HasIntermediateGoals}
 */
proto.skills.HasIntermediateGoals.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.HasIntermediateGoals;
  return proto.skills.HasIntermediateGoals.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.HasIntermediateGoals} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.HasIntermediateGoals}
 */
proto.skills.HasIntermediateGoals.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasUpGoal(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasTraverseGoal(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDownGoal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.HasIntermediateGoals.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.HasIntermediateGoals.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.HasIntermediateGoals} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.HasIntermediateGoals.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasUpGoal();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getHasTraverseGoal();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getHasDownGoal();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool has_up_goal = 1;
 * @return {boolean}
 */
proto.skills.HasIntermediateGoals.prototype.getHasUpGoal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.HasIntermediateGoals} returns this
 */
proto.skills.HasIntermediateGoals.prototype.setHasUpGoal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool has_traverse_goal = 2;
 * @return {boolean}
 */
proto.skills.HasIntermediateGoals.prototype.getHasTraverseGoal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.HasIntermediateGoals} returns this
 */
proto.skills.HasIntermediateGoals.prototype.setHasTraverseGoal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool has_down_goal = 3;
 * @return {boolean}
 */
proto.skills.HasIntermediateGoals.prototype.getHasDownGoal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.HasIntermediateGoals} returns this
 */
proto.skills.HasIntermediateGoals.prototype.setHasDownGoal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.UpAndOverProgressUpperBounds.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.UpAndOverProgressUpperBounds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.UpAndOverProgressUpperBounds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UpAndOverProgressUpperBounds.toObject = function(includeInstance, msg) {
  var f, obj = {
upProgress: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
traverseProgress: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
downProgress: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.UpAndOverProgressUpperBounds}
 */
proto.skills.UpAndOverProgressUpperBounds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.UpAndOverProgressUpperBounds;
  return proto.skills.UpAndOverProgressUpperBounds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.UpAndOverProgressUpperBounds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.UpAndOverProgressUpperBounds}
 */
proto.skills.UpAndOverProgressUpperBounds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUpProgress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTraverseProgress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDownProgress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.UpAndOverProgressUpperBounds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.UpAndOverProgressUpperBounds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.UpAndOverProgressUpperBounds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.UpAndOverProgressUpperBounds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpProgress();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTraverseProgress();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getDownProgress();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double up_progress = 1;
 * @return {number}
 */
proto.skills.UpAndOverProgressUpperBounds.prototype.getUpProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.UpAndOverProgressUpperBounds} returns this
 */
proto.skills.UpAndOverProgressUpperBounds.prototype.setUpProgress = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double traverse_progress = 2;
 * @return {number}
 */
proto.skills.UpAndOverProgressUpperBounds.prototype.getTraverseProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.UpAndOverProgressUpperBounds} returns this
 */
proto.skills.UpAndOverProgressUpperBounds.prototype.setTraverseProgress = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double down_progress = 3;
 * @return {number}
 */
proto.skills.UpAndOverProgressUpperBounds.prototype.getDownProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.UpAndOverProgressUpperBounds} returns this
 */
proto.skills.UpAndOverProgressUpperBounds.prototype.setDownProgress = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.TraversalStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.TraversalStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.TraversalStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TraversalStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
upAndOverState: jspb.Message.getFieldWithDefault(msg, 1, 0),
hasIntermediateGoals: (f = msg.getHasIntermediateGoals()) && proto.skills.HasIntermediateGoals.toObject(includeInstance, f),
upAndOverProgressUpperBounds: (f = msg.getUpAndOverProgressUpperBounds()) && proto.skills.UpAndOverProgressUpperBounds.toObject(includeInstance, f),
distanceSegments: (f = msg.getDistanceSegments()) && proto.skills.DistanceSegments.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.TraversalStatus}
 */
proto.skills.TraversalStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.TraversalStatus;
  return proto.skills.TraversalStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.TraversalStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.TraversalStatus}
 */
proto.skills.TraversalStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.skills.UpAndOverState.Enum} */ (reader.readEnum());
      msg.setUpAndOverState(value);
      break;
    case 2:
      var value = new proto.skills.HasIntermediateGoals;
      reader.readMessage(value,proto.skills.HasIntermediateGoals.deserializeBinaryFromReader);
      msg.setHasIntermediateGoals(value);
      break;
    case 3:
      var value = new proto.skills.UpAndOverProgressUpperBounds;
      reader.readMessage(value,proto.skills.UpAndOverProgressUpperBounds.deserializeBinaryFromReader);
      msg.setUpAndOverProgressUpperBounds(value);
      break;
    case 4:
      var value = new proto.skills.DistanceSegments;
      reader.readMessage(value,proto.skills.DistanceSegments.deserializeBinaryFromReader);
      msg.setDistanceSegments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.TraversalStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.TraversalStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.TraversalStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TraversalStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpAndOverState();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getHasIntermediateGoals();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skills.HasIntermediateGoals.serializeBinaryToWriter
    );
  }
  f = message.getUpAndOverProgressUpperBounds();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skills.UpAndOverProgressUpperBounds.serializeBinaryToWriter
    );
  }
  f = message.getDistanceSegments();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.skills.DistanceSegments.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpAndOverState.Enum up_and_over_state = 1;
 * @return {!proto.skills.UpAndOverState.Enum}
 */
proto.skills.TraversalStatus.prototype.getUpAndOverState = function() {
  return /** @type {!proto.skills.UpAndOverState.Enum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.skills.UpAndOverState.Enum} value
 * @return {!proto.skills.TraversalStatus} returns this
 */
proto.skills.TraversalStatus.prototype.setUpAndOverState = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional HasIntermediateGoals has_intermediate_goals = 2;
 * @return {?proto.skills.HasIntermediateGoals}
 */
proto.skills.TraversalStatus.prototype.getHasIntermediateGoals = function() {
  return /** @type{?proto.skills.HasIntermediateGoals} */ (
    jspb.Message.getWrapperField(this, proto.skills.HasIntermediateGoals, 2));
};


/**
 * @param {?proto.skills.HasIntermediateGoals|undefined} value
 * @return {!proto.skills.TraversalStatus} returns this
*/
proto.skills.TraversalStatus.prototype.setHasIntermediateGoals = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.TraversalStatus} returns this
 */
proto.skills.TraversalStatus.prototype.clearHasIntermediateGoals = function() {
  return this.setHasIntermediateGoals(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.TraversalStatus.prototype.hasHasIntermediateGoals = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UpAndOverProgressUpperBounds up_and_over_progress_upper_bounds = 3;
 * @return {?proto.skills.UpAndOverProgressUpperBounds}
 */
proto.skills.TraversalStatus.prototype.getUpAndOverProgressUpperBounds = function() {
  return /** @type{?proto.skills.UpAndOverProgressUpperBounds} */ (
    jspb.Message.getWrapperField(this, proto.skills.UpAndOverProgressUpperBounds, 3));
};


/**
 * @param {?proto.skills.UpAndOverProgressUpperBounds|undefined} value
 * @return {!proto.skills.TraversalStatus} returns this
*/
proto.skills.TraversalStatus.prototype.setUpAndOverProgressUpperBounds = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.TraversalStatus} returns this
 */
proto.skills.TraversalStatus.prototype.clearUpAndOverProgressUpperBounds = function() {
  return this.setUpAndOverProgressUpperBounds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.TraversalStatus.prototype.hasUpAndOverProgressUpperBounds = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DistanceSegments distance_segments = 4;
 * @return {?proto.skills.DistanceSegments}
 */
proto.skills.TraversalStatus.prototype.getDistanceSegments = function() {
  return /** @type{?proto.skills.DistanceSegments} */ (
    jspb.Message.getWrapperField(this, proto.skills.DistanceSegments, 4));
};


/**
 * @param {?proto.skills.DistanceSegments|undefined} value
 * @return {!proto.skills.TraversalStatus} returns this
*/
proto.skills.TraversalStatus.prototype.setDistanceSegments = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.TraversalStatus} returns this
 */
proto.skills.TraversalStatus.prototype.clearDistanceSegments = function() {
  return this.setDistanceSegments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.TraversalStatus.prototype.hasDistanceSegments = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GraphStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GraphStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GraphStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GraphStatus.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GraphStatus}
 */
proto.skills.GraphStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GraphStatus;
  return proto.skills.GraphStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GraphStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GraphStatus}
 */
proto.skills.GraphStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GraphStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GraphStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GraphStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GraphStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skills.GoToWaypointStatus.repeatedFields_ = [17];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.skills.GoToWaypointStatus.oneofGroups_ = [[9,10]];

/**
 * @enum {number}
 */
proto.skills.GoToWaypointStatus.TaskStatusCase = {
  TASK_STATUS_NOT_SET: 0,
  TRAVERSAL_STATUS: 9,
  GRAPH_STATUS: 10
};

/**
 * @return {proto.skills.GoToWaypointStatus.TaskStatusCase}
 */
proto.skills.GoToWaypointStatus.prototype.getTaskStatusCase = function() {
  return /** @type {proto.skills.GoToWaypointStatus.TaskStatusCase} */(jspb.Message.computeOneofCase(this, proto.skills.GoToWaypointStatus.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GoToWaypointStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GoToWaypointStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GoToWaypointStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GoToWaypointStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
nonce: jspb.Message.getFieldWithDefault(msg, 2, 0),
acceptedTargetNonce: jspb.Message.getFieldWithDefault(msg, 11, 0),
distanceTraveled: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
totalDistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
target: (f = msg.getTarget()) && proto.skills.TargetWaypoint.toObject(includeInstance, f),
state: jspb.Message.getFieldWithDefault(msg, 6, 0),
source: jspb.Message.getFieldWithDefault(msg, 7, 0),
triggeringFault: jspb.Message.getFieldWithDefault(msg, 13, 0),
failingFault: jspb.Message.getFieldWithDefault(msg, 16, 0),
motionArgs: (f = msg.getMotionArgs()) && proto.skills.MotionArgs.toObject(includeInstance, f),
traversalStatus: (f = msg.getTraversalStatus()) && proto.skills.TraversalStatus.toObject(includeInstance, f),
graphStatus: (f = msg.getGraphStatus()) && proto.skills.GraphStatus.toObject(includeInstance, f),
adjustedTargetWaypointInNav: (f = msg.getAdjustedTargetWaypointInNav()) && proto.skills.Waypoint.toObject(includeInstance, f),
landOnComplete: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
criticalRtxType: jspb.Message.getFieldWithDefault(msg, 15, 0),
intermediateWaypointsList: jspb.Message.toObjectList(msg.getIntermediateWaypointsList(),
    proto.skills.Waypoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GoToWaypointStatus}
 */
proto.skills.GoToWaypointStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GoToWaypointStatus;
  return proto.skills.GoToWaypointStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GoToWaypointStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GoToWaypointStatus}
 */
proto.skills.GoToWaypointStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNonce(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAcceptedTargetNonce(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistanceTraveled(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTotalDistance(value);
      break;
    case 5:
      var value = new proto.skills.TargetWaypoint;
      reader.readMessage(value,proto.skills.TargetWaypoint.deserializeBinaryFromReader);
      msg.setTarget(value);
      break;
    case 6:
      var value = /** @type {!proto.skills.GoToWaypointState.Enum} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 7:
      var value = /** @type {!proto.skills.GoToWaypointSource.Enum} */ (reader.readEnum());
      msg.setSource(value);
      break;
    case 13:
      var value = /** @type {!proto.common.VehicleFault.Enum} */ (reader.readEnum());
      msg.setTriggeringFault(value);
      break;
    case 16:
      var value = /** @type {!proto.common.VehicleFault.Enum} */ (reader.readEnum());
      msg.setFailingFault(value);
      break;
    case 8:
      var value = new proto.skills.MotionArgs;
      reader.readMessage(value,proto.skills.MotionArgs.deserializeBinaryFromReader);
      msg.setMotionArgs(value);
      break;
    case 9:
      var value = new proto.skills.TraversalStatus;
      reader.readMessage(value,proto.skills.TraversalStatus.deserializeBinaryFromReader);
      msg.setTraversalStatus(value);
      break;
    case 10:
      var value = new proto.skills.GraphStatus;
      reader.readMessage(value,proto.skills.GraphStatus.deserializeBinaryFromReader);
      msg.setGraphStatus(value);
      break;
    case 12:
      var value = new proto.skills.Waypoint;
      reader.readMessage(value,proto.skills.Waypoint.deserializeBinaryFromReader);
      msg.setAdjustedTargetWaypointInNav(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLandOnComplete(value);
      break;
    case 15:
      var value = /** @type {!proto.skills.CriticalRTXType.Enum} */ (reader.readEnum());
      msg.setCriticalRtxType(value);
      break;
    case 17:
      var value = new proto.skills.Waypoint;
      reader.readMessage(value,proto.skills.Waypoint.deserializeBinaryFromReader);
      msg.addIntermediateWaypoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GoToWaypointStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GoToWaypointStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GoToWaypointStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GoToWaypointStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNonce();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAcceptedTargetNonce();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getDistanceTraveled();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getTotalDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getTarget();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.skills.TargetWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSource();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getTriggeringFault();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getFailingFault();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getMotionArgs();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.skills.MotionArgs.serializeBinaryToWriter
    );
  }
  f = message.getTraversalStatus();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.skills.TraversalStatus.serializeBinaryToWriter
    );
  }
  f = message.getGraphStatus();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.skills.GraphStatus.serializeBinaryToWriter
    );
  }
  f = message.getAdjustedTargetWaypointInNav();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.skills.Waypoint.serializeBinaryToWriter
    );
  }
  f = message.getLandOnComplete();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getCriticalRtxType();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getIntermediateWaypointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.skills.Waypoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skills.GoToWaypointStatus.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.GoToWaypointStatus} returns this
 */
proto.skills.GoToWaypointStatus.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 nonce = 2;
 * @return {number}
 */
proto.skills.GoToWaypointStatus.prototype.getNonce = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.GoToWaypointStatus} returns this
 */
proto.skills.GoToWaypointStatus.prototype.setNonce = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 accepted_target_nonce = 11;
 * @return {number}
 */
proto.skills.GoToWaypointStatus.prototype.getAcceptedTargetNonce = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.GoToWaypointStatus} returns this
 */
proto.skills.GoToWaypointStatus.prototype.setAcceptedTargetNonce = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional float distance_traveled = 3;
 * @return {number}
 */
proto.skills.GoToWaypointStatus.prototype.getDistanceTraveled = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.GoToWaypointStatus} returns this
 */
proto.skills.GoToWaypointStatus.prototype.setDistanceTraveled = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float total_distance = 4;
 * @return {number}
 */
proto.skills.GoToWaypointStatus.prototype.getTotalDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.GoToWaypointStatus} returns this
 */
proto.skills.GoToWaypointStatus.prototype.setTotalDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional TargetWaypoint target = 5;
 * @return {?proto.skills.TargetWaypoint}
 */
proto.skills.GoToWaypointStatus.prototype.getTarget = function() {
  return /** @type{?proto.skills.TargetWaypoint} */ (
    jspb.Message.getWrapperField(this, proto.skills.TargetWaypoint, 5));
};


/**
 * @param {?proto.skills.TargetWaypoint|undefined} value
 * @return {!proto.skills.GoToWaypointStatus} returns this
*/
proto.skills.GoToWaypointStatus.prototype.setTarget = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GoToWaypointStatus} returns this
 */
proto.skills.GoToWaypointStatus.prototype.clearTarget = function() {
  return this.setTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GoToWaypointStatus.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional GoToWaypointState.Enum state = 6;
 * @return {!proto.skills.GoToWaypointState.Enum}
 */
proto.skills.GoToWaypointStatus.prototype.getState = function() {
  return /** @type {!proto.skills.GoToWaypointState.Enum} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.skills.GoToWaypointState.Enum} value
 * @return {!proto.skills.GoToWaypointStatus} returns this
 */
proto.skills.GoToWaypointStatus.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional GoToWaypointSource.Enum source = 7;
 * @return {!proto.skills.GoToWaypointSource.Enum}
 */
proto.skills.GoToWaypointStatus.prototype.getSource = function() {
  return /** @type {!proto.skills.GoToWaypointSource.Enum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.skills.GoToWaypointSource.Enum} value
 * @return {!proto.skills.GoToWaypointStatus} returns this
 */
proto.skills.GoToWaypointStatus.prototype.setSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional common.VehicleFault.Enum triggering_fault = 13;
 * @return {!proto.common.VehicleFault.Enum}
 */
proto.skills.GoToWaypointStatus.prototype.getTriggeringFault = function() {
  return /** @type {!proto.common.VehicleFault.Enum} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.common.VehicleFault.Enum} value
 * @return {!proto.skills.GoToWaypointStatus} returns this
 */
proto.skills.GoToWaypointStatus.prototype.setTriggeringFault = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional common.VehicleFault.Enum failing_fault = 16;
 * @return {!proto.common.VehicleFault.Enum}
 */
proto.skills.GoToWaypointStatus.prototype.getFailingFault = function() {
  return /** @type {!proto.common.VehicleFault.Enum} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.common.VehicleFault.Enum} value
 * @return {!proto.skills.GoToWaypointStatus} returns this
 */
proto.skills.GoToWaypointStatus.prototype.setFailingFault = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * optional MotionArgs motion_args = 8;
 * @return {?proto.skills.MotionArgs}
 */
proto.skills.GoToWaypointStatus.prototype.getMotionArgs = function() {
  return /** @type{?proto.skills.MotionArgs} */ (
    jspb.Message.getWrapperField(this, proto.skills.MotionArgs, 8));
};


/**
 * @param {?proto.skills.MotionArgs|undefined} value
 * @return {!proto.skills.GoToWaypointStatus} returns this
*/
proto.skills.GoToWaypointStatus.prototype.setMotionArgs = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GoToWaypointStatus} returns this
 */
proto.skills.GoToWaypointStatus.prototype.clearMotionArgs = function() {
  return this.setMotionArgs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GoToWaypointStatus.prototype.hasMotionArgs = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional TraversalStatus traversal_status = 9;
 * @return {?proto.skills.TraversalStatus}
 */
proto.skills.GoToWaypointStatus.prototype.getTraversalStatus = function() {
  return /** @type{?proto.skills.TraversalStatus} */ (
    jspb.Message.getWrapperField(this, proto.skills.TraversalStatus, 9));
};


/**
 * @param {?proto.skills.TraversalStatus|undefined} value
 * @return {!proto.skills.GoToWaypointStatus} returns this
*/
proto.skills.GoToWaypointStatus.prototype.setTraversalStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.skills.GoToWaypointStatus.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GoToWaypointStatus} returns this
 */
proto.skills.GoToWaypointStatus.prototype.clearTraversalStatus = function() {
  return this.setTraversalStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GoToWaypointStatus.prototype.hasTraversalStatus = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional GraphStatus graph_status = 10;
 * @return {?proto.skills.GraphStatus}
 */
proto.skills.GoToWaypointStatus.prototype.getGraphStatus = function() {
  return /** @type{?proto.skills.GraphStatus} */ (
    jspb.Message.getWrapperField(this, proto.skills.GraphStatus, 10));
};


/**
 * @param {?proto.skills.GraphStatus|undefined} value
 * @return {!proto.skills.GoToWaypointStatus} returns this
*/
proto.skills.GoToWaypointStatus.prototype.setGraphStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.skills.GoToWaypointStatus.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GoToWaypointStatus} returns this
 */
proto.skills.GoToWaypointStatus.prototype.clearGraphStatus = function() {
  return this.setGraphStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GoToWaypointStatus.prototype.hasGraphStatus = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Waypoint adjusted_target_waypoint_in_nav = 12;
 * @return {?proto.skills.Waypoint}
 */
proto.skills.GoToWaypointStatus.prototype.getAdjustedTargetWaypointInNav = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, proto.skills.Waypoint, 12));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.skills.GoToWaypointStatus} returns this
*/
proto.skills.GoToWaypointStatus.prototype.setAdjustedTargetWaypointInNav = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GoToWaypointStatus} returns this
 */
proto.skills.GoToWaypointStatus.prototype.clearAdjustedTargetWaypointInNav = function() {
  return this.setAdjustedTargetWaypointInNav(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GoToWaypointStatus.prototype.hasAdjustedTargetWaypointInNav = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool land_on_complete = 14;
 * @return {boolean}
 */
proto.skills.GoToWaypointStatus.prototype.getLandOnComplete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.GoToWaypointStatus} returns this
 */
proto.skills.GoToWaypointStatus.prototype.setLandOnComplete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional CriticalRTXType.Enum critical_rtx_type = 15;
 * @return {!proto.skills.CriticalRTXType.Enum}
 */
proto.skills.GoToWaypointStatus.prototype.getCriticalRtxType = function() {
  return /** @type {!proto.skills.CriticalRTXType.Enum} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.skills.CriticalRTXType.Enum} value
 * @return {!proto.skills.GoToWaypointStatus} returns this
 */
proto.skills.GoToWaypointStatus.prototype.setCriticalRtxType = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * repeated Waypoint intermediate_waypoints = 17;
 * @return {!Array<!proto.skills.Waypoint>}
 */
proto.skills.GoToWaypointStatus.prototype.getIntermediateWaypointsList = function() {
  return /** @type{!Array<!proto.skills.Waypoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skills.Waypoint, 17));
};


/**
 * @param {!Array<!proto.skills.Waypoint>} value
 * @return {!proto.skills.GoToWaypointStatus} returns this
*/
proto.skills.GoToWaypointStatus.prototype.setIntermediateWaypointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.skills.Waypoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skills.Waypoint}
 */
proto.skills.GoToWaypointStatus.prototype.addIntermediateWaypoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.skills.Waypoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skills.GoToWaypointStatus} returns this
 */
proto.skills.GoToWaypointStatus.prototype.clearIntermediateWaypointsList = function() {
  return this.setIntermediateWaypointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.IntermediateGoals.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.IntermediateGoals.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.IntermediateGoals} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.IntermediateGoals.toObject = function(includeInstance, msg) {
  var f, obj = {
upGoal: (f = msg.getUpGoal()) && proto.skills.Waypoint.toObject(includeInstance, f),
traverseGoal: (f = msg.getTraverseGoal()) && proto.skills.Waypoint.toObject(includeInstance, f),
downGoal: (f = msg.getDownGoal()) && proto.skills.Waypoint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.IntermediateGoals}
 */
proto.skills.IntermediateGoals.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.IntermediateGoals;
  return proto.skills.IntermediateGoals.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.IntermediateGoals} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.IntermediateGoals}
 */
proto.skills.IntermediateGoals.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.Waypoint;
      reader.readMessage(value,proto.skills.Waypoint.deserializeBinaryFromReader);
      msg.setUpGoal(value);
      break;
    case 2:
      var value = new proto.skills.Waypoint;
      reader.readMessage(value,proto.skills.Waypoint.deserializeBinaryFromReader);
      msg.setTraverseGoal(value);
      break;
    case 3:
      var value = new proto.skills.Waypoint;
      reader.readMessage(value,proto.skills.Waypoint.deserializeBinaryFromReader);
      msg.setDownGoal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.IntermediateGoals.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.IntermediateGoals.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.IntermediateGoals} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.IntermediateGoals.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpGoal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.Waypoint.serializeBinaryToWriter
    );
  }
  f = message.getTraverseGoal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.skills.Waypoint.serializeBinaryToWriter
    );
  }
  f = message.getDownGoal();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skills.Waypoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional Waypoint up_goal = 1;
 * @return {?proto.skills.Waypoint}
 */
proto.skills.IntermediateGoals.prototype.getUpGoal = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, proto.skills.Waypoint, 1));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.skills.IntermediateGoals} returns this
*/
proto.skills.IntermediateGoals.prototype.setUpGoal = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.IntermediateGoals} returns this
 */
proto.skills.IntermediateGoals.prototype.clearUpGoal = function() {
  return this.setUpGoal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.IntermediateGoals.prototype.hasUpGoal = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Waypoint traverse_goal = 2;
 * @return {?proto.skills.Waypoint}
 */
proto.skills.IntermediateGoals.prototype.getTraverseGoal = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, proto.skills.Waypoint, 2));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.skills.IntermediateGoals} returns this
*/
proto.skills.IntermediateGoals.prototype.setTraverseGoal = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.IntermediateGoals} returns this
 */
proto.skills.IntermediateGoals.prototype.clearTraverseGoal = function() {
  return this.setTraverseGoal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.IntermediateGoals.prototype.hasTraverseGoal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Waypoint down_goal = 3;
 * @return {?proto.skills.Waypoint}
 */
proto.skills.IntermediateGoals.prototype.getDownGoal = function() {
  return /** @type{?proto.skills.Waypoint} */ (
    jspb.Message.getWrapperField(this, proto.skills.Waypoint, 3));
};


/**
 * @param {?proto.skills.Waypoint|undefined} value
 * @return {!proto.skills.IntermediateGoals} returns this
*/
proto.skills.IntermediateGoals.prototype.setDownGoal = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.IntermediateGoals} returns this
 */
proto.skills.IntermediateGoals.prototype.clearDownGoal = function() {
  return this.setDownGoal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.IntermediateGoals.prototype.hasDownGoal = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.TraversalStatusInternal.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.TraversalStatusInternal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.TraversalStatusInternal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TraversalStatusInternal.toObject = function(includeInstance, msg) {
  var f, obj = {
intermediateGoals: (f = msg.getIntermediateGoals()) && proto.skills.IntermediateGoals.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.TraversalStatusInternal}
 */
proto.skills.TraversalStatusInternal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.TraversalStatusInternal;
  return proto.skills.TraversalStatusInternal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.TraversalStatusInternal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.TraversalStatusInternal}
 */
proto.skills.TraversalStatusInternal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skills.IntermediateGoals;
      reader.readMessage(value,proto.skills.IntermediateGoals.deserializeBinaryFromReader);
      msg.setIntermediateGoals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.TraversalStatusInternal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.TraversalStatusInternal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.TraversalStatusInternal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.TraversalStatusInternal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntermediateGoals();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skills.IntermediateGoals.serializeBinaryToWriter
    );
  }
};


/**
 * optional IntermediateGoals intermediate_goals = 1;
 * @return {?proto.skills.IntermediateGoals}
 */
proto.skills.TraversalStatusInternal.prototype.getIntermediateGoals = function() {
  return /** @type{?proto.skills.IntermediateGoals} */ (
    jspb.Message.getWrapperField(this, proto.skills.IntermediateGoals, 1));
};


/**
 * @param {?proto.skills.IntermediateGoals|undefined} value
 * @return {!proto.skills.TraversalStatusInternal} returns this
*/
proto.skills.TraversalStatusInternal.prototype.setIntermediateGoals = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.TraversalStatusInternal} returns this
 */
proto.skills.TraversalStatusInternal.prototype.clearIntermediateGoals = function() {
  return this.setIntermediateGoals(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.TraversalStatusInternal.prototype.hasIntermediateGoals = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GraphStatusInternal.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GraphStatusInternal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GraphStatusInternal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GraphStatusInternal.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GraphStatusInternal}
 */
proto.skills.GraphStatusInternal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GraphStatusInternal;
  return proto.skills.GraphStatusInternal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GraphStatusInternal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GraphStatusInternal}
 */
proto.skills.GraphStatusInternal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GraphStatusInternal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GraphStatusInternal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GraphStatusInternal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GraphStatusInternal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.WaypointHeadingsInternal.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.WaypointHeadingsInternal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.WaypointHeadingsInternal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WaypointHeadingsInternal.toObject = function(includeInstance, msg) {
  var f, obj = {
startHeadingInNav: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
targetHeadingInNav: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
currentHeadingInNav: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
relativeHeading: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.WaypointHeadingsInternal}
 */
proto.skills.WaypointHeadingsInternal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.WaypointHeadingsInternal;
  return proto.skills.WaypointHeadingsInternal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.WaypointHeadingsInternal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.WaypointHeadingsInternal}
 */
proto.skills.WaypointHeadingsInternal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStartHeadingInNav(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTargetHeadingInNav(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCurrentHeadingInNav(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRelativeHeading(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.WaypointHeadingsInternal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.WaypointHeadingsInternal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.WaypointHeadingsInternal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.WaypointHeadingsInternal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartHeadingInNav();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getTargetHeadingInNav();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getCurrentHeadingInNav();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getRelativeHeading();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional float start_heading_in_nav = 1;
 * @return {number}
 */
proto.skills.WaypointHeadingsInternal.prototype.getStartHeadingInNav = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.WaypointHeadingsInternal} returns this
 */
proto.skills.WaypointHeadingsInternal.prototype.setStartHeadingInNav = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float target_heading_in_nav = 2;
 * @return {number}
 */
proto.skills.WaypointHeadingsInternal.prototype.getTargetHeadingInNav = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.WaypointHeadingsInternal} returns this
 */
proto.skills.WaypointHeadingsInternal.prototype.setTargetHeadingInNav = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float current_heading_in_nav = 3;
 * @return {number}
 */
proto.skills.WaypointHeadingsInternal.prototype.getCurrentHeadingInNav = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.WaypointHeadingsInternal} returns this
 */
proto.skills.WaypointHeadingsInternal.prototype.setCurrentHeadingInNav = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float relative_heading = 4;
 * @return {number}
 */
proto.skills.WaypointHeadingsInternal.prototype.getRelativeHeading = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skills.WaypointHeadingsInternal} returns this
 */
proto.skills.WaypointHeadingsInternal.prototype.setRelativeHeading = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.skills.GoToWaypointStatusInternal.oneofGroups_ = [[5,6]];

/**
 * @enum {number}
 */
proto.skills.GoToWaypointStatusInternal.TaskStatusInternalCase = {
  TASK_STATUS_INTERNAL_NOT_SET: 0,
  TRAVERSAL_STATUS_INTERNAL: 5,
  GRAPH_STATUS_INTERNAL: 6
};

/**
 * @return {proto.skills.GoToWaypointStatusInternal.TaskStatusInternalCase}
 */
proto.skills.GoToWaypointStatusInternal.prototype.getTaskStatusInternalCase = function() {
  return /** @type {proto.skills.GoToWaypointStatusInternal.TaskStatusInternalCase} */(jspb.Message.computeOneofCase(this, proto.skills.GoToWaypointStatusInternal.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.GoToWaypointStatusInternal.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.GoToWaypointStatusInternal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.GoToWaypointStatusInternal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GoToWaypointStatusInternal.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
startInNav: (f = msg.getStartInNav()) && pbtypes_gen_body_trans_pb.TransPb.toObject(includeInstance, f),
targetInNav: (f = msg.getTargetInNav()) && pbtypes_gen_body_trans_pb.TransPb.toObject(includeInstance, f),
waypointHeadingsInternal: (f = msg.getWaypointHeadingsInternal()) && proto.skills.WaypointHeadingsInternal.toObject(includeInstance, f),
traversalStatusInternal: (f = msg.getTraversalStatusInternal()) && proto.skills.TraversalStatusInternal.toObject(includeInstance, f),
graphStatusInternal: (f = msg.getGraphStatusInternal()) && proto.skills.GraphStatusInternal.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.GoToWaypointStatusInternal}
 */
proto.skills.GoToWaypointStatusInternal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.GoToWaypointStatusInternal;
  return proto.skills.GoToWaypointStatusInternal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.GoToWaypointStatusInternal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.GoToWaypointStatusInternal}
 */
proto.skills.GoToWaypointStatusInternal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_body_trans_pb.TransPb;
      reader.readMessage(value,pbtypes_gen_body_trans_pb.TransPb.deserializeBinaryFromReader);
      msg.setStartInNav(value);
      break;
    case 3:
      var value = new pbtypes_gen_body_trans_pb.TransPb;
      reader.readMessage(value,pbtypes_gen_body_trans_pb.TransPb.deserializeBinaryFromReader);
      msg.setTargetInNav(value);
      break;
    case 4:
      var value = new proto.skills.WaypointHeadingsInternal;
      reader.readMessage(value,proto.skills.WaypointHeadingsInternal.deserializeBinaryFromReader);
      msg.setWaypointHeadingsInternal(value);
      break;
    case 5:
      var value = new proto.skills.TraversalStatusInternal;
      reader.readMessage(value,proto.skills.TraversalStatusInternal.deserializeBinaryFromReader);
      msg.setTraversalStatusInternal(value);
      break;
    case 6:
      var value = new proto.skills.GraphStatusInternal;
      reader.readMessage(value,proto.skills.GraphStatusInternal.deserializeBinaryFromReader);
      msg.setGraphStatusInternal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.GoToWaypointStatusInternal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.GoToWaypointStatusInternal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.GoToWaypointStatusInternal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.GoToWaypointStatusInternal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStartInNav();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_body_trans_pb.TransPb.serializeBinaryToWriter
    );
  }
  f = message.getTargetInNav();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_gen_body_trans_pb.TransPb.serializeBinaryToWriter
    );
  }
  f = message.getWaypointHeadingsInternal();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.skills.WaypointHeadingsInternal.serializeBinaryToWriter
    );
  }
  f = message.getTraversalStatusInternal();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.skills.TraversalStatusInternal.serializeBinaryToWriter
    );
  }
  f = message.getGraphStatusInternal();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.skills.GraphStatusInternal.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skills.GoToWaypointStatusInternal.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.GoToWaypointStatusInternal} returns this
 */
proto.skills.GoToWaypointStatusInternal.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional body.TransPb start_in_nav = 2;
 * @return {?proto.body.TransPb}
 */
proto.skills.GoToWaypointStatusInternal.prototype.getStartInNav = function() {
  return /** @type{?proto.body.TransPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_body_trans_pb.TransPb, 2));
};


/**
 * @param {?proto.body.TransPb|undefined} value
 * @return {!proto.skills.GoToWaypointStatusInternal} returns this
*/
proto.skills.GoToWaypointStatusInternal.prototype.setStartInNav = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GoToWaypointStatusInternal} returns this
 */
proto.skills.GoToWaypointStatusInternal.prototype.clearStartInNav = function() {
  return this.setStartInNav(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GoToWaypointStatusInternal.prototype.hasStartInNav = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional body.TransPb target_in_nav = 3;
 * @return {?proto.body.TransPb}
 */
proto.skills.GoToWaypointStatusInternal.prototype.getTargetInNav = function() {
  return /** @type{?proto.body.TransPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_body_trans_pb.TransPb, 3));
};


/**
 * @param {?proto.body.TransPb|undefined} value
 * @return {!proto.skills.GoToWaypointStatusInternal} returns this
*/
proto.skills.GoToWaypointStatusInternal.prototype.setTargetInNav = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GoToWaypointStatusInternal} returns this
 */
proto.skills.GoToWaypointStatusInternal.prototype.clearTargetInNav = function() {
  return this.setTargetInNav(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GoToWaypointStatusInternal.prototype.hasTargetInNav = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional WaypointHeadingsInternal waypoint_headings_internal = 4;
 * @return {?proto.skills.WaypointHeadingsInternal}
 */
proto.skills.GoToWaypointStatusInternal.prototype.getWaypointHeadingsInternal = function() {
  return /** @type{?proto.skills.WaypointHeadingsInternal} */ (
    jspb.Message.getWrapperField(this, proto.skills.WaypointHeadingsInternal, 4));
};


/**
 * @param {?proto.skills.WaypointHeadingsInternal|undefined} value
 * @return {!proto.skills.GoToWaypointStatusInternal} returns this
*/
proto.skills.GoToWaypointStatusInternal.prototype.setWaypointHeadingsInternal = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GoToWaypointStatusInternal} returns this
 */
proto.skills.GoToWaypointStatusInternal.prototype.clearWaypointHeadingsInternal = function() {
  return this.setWaypointHeadingsInternal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GoToWaypointStatusInternal.prototype.hasWaypointHeadingsInternal = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TraversalStatusInternal traversal_status_internal = 5;
 * @return {?proto.skills.TraversalStatusInternal}
 */
proto.skills.GoToWaypointStatusInternal.prototype.getTraversalStatusInternal = function() {
  return /** @type{?proto.skills.TraversalStatusInternal} */ (
    jspb.Message.getWrapperField(this, proto.skills.TraversalStatusInternal, 5));
};


/**
 * @param {?proto.skills.TraversalStatusInternal|undefined} value
 * @return {!proto.skills.GoToWaypointStatusInternal} returns this
*/
proto.skills.GoToWaypointStatusInternal.prototype.setTraversalStatusInternal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.skills.GoToWaypointStatusInternal.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GoToWaypointStatusInternal} returns this
 */
proto.skills.GoToWaypointStatusInternal.prototype.clearTraversalStatusInternal = function() {
  return this.setTraversalStatusInternal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GoToWaypointStatusInternal.prototype.hasTraversalStatusInternal = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional GraphStatusInternal graph_status_internal = 6;
 * @return {?proto.skills.GraphStatusInternal}
 */
proto.skills.GoToWaypointStatusInternal.prototype.getGraphStatusInternal = function() {
  return /** @type{?proto.skills.GraphStatusInternal} */ (
    jspb.Message.getWrapperField(this, proto.skills.GraphStatusInternal, 6));
};


/**
 * @param {?proto.skills.GraphStatusInternal|undefined} value
 * @return {!proto.skills.GoToWaypointStatusInternal} returns this
*/
proto.skills.GoToWaypointStatusInternal.prototype.setGraphStatusInternal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.skills.GoToWaypointStatusInternal.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skills.GoToWaypointStatusInternal} returns this
 */
proto.skills.GoToWaypointStatusInternal.prototype.clearGraphStatusInternal = function() {
  return this.setGraphStatusInternal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skills.GoToWaypointStatusInternal.prototype.hasGraphStatusInternal = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.SkillsRTXSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.SkillsRTXSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.SkillsRTXSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SkillsRTXSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
alwaysLowBatteryRtxWithComms: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
ignoreLostPhoneConnection: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.SkillsRTXSettings}
 */
proto.skills.SkillsRTXSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.SkillsRTXSettings;
  return proto.skills.SkillsRTXSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.SkillsRTXSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.SkillsRTXSettings}
 */
proto.skills.SkillsRTXSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlwaysLowBatteryRtxWithComms(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreLostPhoneConnection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.SkillsRTXSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.SkillsRTXSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.SkillsRTXSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.SkillsRTXSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAlwaysLowBatteryRtxWithComms();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIgnoreLostPhoneConnection();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.skills.SkillsRTXSettings.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skills.SkillsRTXSettings} returns this
 */
proto.skills.SkillsRTXSettings.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool always_low_battery_rtx_with_comms = 2;
 * @return {boolean}
 */
proto.skills.SkillsRTXSettings.prototype.getAlwaysLowBatteryRtxWithComms = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.SkillsRTXSettings} returns this
 */
proto.skills.SkillsRTXSettings.prototype.setAlwaysLowBatteryRtxWithComms = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool ignore_lost_phone_connection = 3;
 * @return {boolean}
 */
proto.skills.SkillsRTXSettings.prototype.getIgnoreLostPhoneConnection = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skills.SkillsRTXSettings} returns this
 */
proto.skills.SkillsRTXSettings.prototype.setIgnoreLostPhoneConnection = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skills.CriticalRTXType.prototype.toObject = function(opt_includeInstance) {
  return proto.skills.CriticalRTXType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skills.CriticalRTXType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.CriticalRTXType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skills.CriticalRTXType}
 */
proto.skills.CriticalRTXType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skills.CriticalRTXType;
  return proto.skills.CriticalRTXType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skills.CriticalRTXType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skills.CriticalRTXType}
 */
proto.skills.CriticalRTXType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skills.CriticalRTXType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skills.CriticalRTXType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skills.CriticalRTXType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skills.CriticalRTXType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.skills.CriticalRTXType.Enum = {
  NONE: 0,
  LOST_CONNECTION: 1,
  LOW_BATTERY: 2,
  TOO_DARK: 3,
  SAFE_LANDING: 4
};

goog.object.extend(exports, proto.skills);
